import { render, staticRenderFns } from "./slider-list.vue?vue&type=template&id=67e6dc12&scoped=true"
import script from "./slider-list.vue?vue&type=script&lang=js"
export * from "./slider-list.vue?vue&type=script&lang=js"
import style0 from "./slider-list.vue?vue&type=style&index=0&id=67e6dc12&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67e6dc12",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSlider } from 'vuetify/lib/components/VSlider';
installComponents(component, {VSlider})
