<template>
  <v-radio-group
    v-bind="$attrs"
    class="cy-inputs-radios"
    :row="!vertical"
    :column="vertical"
    :value="parseItem(value)"
    v-on="$listeners"
    @change="$emit('input', $event)">
    <v-radio
      v-for="(item, index) in items"
      :key="`radio-item-${index}`"
      :label="parseLabel(item)"
      :value="parseItem(item)"
      class="cy-inputs-radios"
      color="secondary"/>
  </v-radio-group>
</template>

<script>
export default {
  name: 'CyFormsWidgetRadios',
  props: {
    items: {
      type: Array,
      required: true,
    },
    orientation: {
      type: String,
      validator: (orientation) => ['horizontal', 'vertical'].includes(orientation),
      default: 'horizontal',
    },
    value: {
      type: [String, Boolean, Number, Array, Object],
      default: '',
      required: true,
    },
    hasValueMapping: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    vertical () {
      return this.orientation === 'vertical'
    },
  },
  methods: {
    parseItem (item) {
      const value = this.hasValueMapping ? item.label ?? item : item
      if (_.isString(value)) return value
      if (_.isNumber(value)) return Number(value)
      if (_.isBoolean(value)) return Boolean(value)
      return value
    },
    parseLabel (item) {
      const label = this.hasValueMapping ? item.label ?? item : item
      if (_.isString(label)) return label
      if (_.isArray(label)) return label.join(', ')
      return JSON.stringify(label)
    },
  },
}
</script>

<style lang="scss" scoped>
.cy-inputs-radios {
  margin-top: 0;
}
</style>
