import { render, staticRenderFns } from "./copy-btn.vue?vue&type=template&id=bca1df1c&scoped=true"
import script from "./copy-btn.vue?vue&type=script&lang=js"
export * from "./copy-btn.vue?vue&type=script&lang=js"
import style0 from "./copy-btn.vue?vue&type=style&index=0&id=bca1df1c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bca1df1c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
