<template>
  <CyFormsWidgetAutocomplete
    v-bind="$attrs"
    class="cy-inputs-branches"
    persistent-hint
    :placeholder="getPlaceholder"
    :disabled="disabled || _.isEmpty(gitUrl)"
    :error-messages="getErrors"
    :items="branchesFromAPI"
    :hint="getHint"
    :loading="loading"
    :value="value"
    :required="required"
    :allow-free-text="false"
    @blur="$v.value.$touch()"
    @input="$emit('input', $event)"/>
</template>

<script>
import CyFormsWidgetAutocomplete from '@/components/forms-widget/autocomplete.vue'
import { checksPass } from '@/utils/helpers'
import { requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'CyFormsWidgetBranches',
  components: {
    CyFormsWidgetAutocomplete,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    credentialCanonical: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    gitUrl: {
      type: String,
      default: '',
    },
  },
  validations: {
    value: {
      required: requiredIf(function () {
        return this.required
      }),
    },
  },
  data: () => ({
    loading: false,
    branchesFromAPI: [],
    errorsFromAPI: undefined,
  }),
  computed: {
    getErrors () {
      const errors = []
      const { $dirty, required: $vRequired } = this.$v.value

      if ($dirty && !$vRequired && this.required) errors.push(this.$t('forms.fieldRequired'))
      if (!_.isEmpty(this.errorsFromAPI)) {
        const messageFromAPI = _.get(_.first(this.errorsFromAPI), 'message')
        errors.push(`${this.$t('errorsFromAPI')}${messageFromAPI ? `: ${messageFromAPI}` : ''}`)
      }
      return errors
    },
    getHint () {
      return _.isEmpty(this.gitUrl) ? this.$t('selectSourceInput') : ''
    },
    getPlaceholder () {
      if (this.loading) return `${this.$t('forms.loading')}...`
      if (_.isEmpty(this.gitUrl)) return this.$t('notAvailable')
      return ''
    },
  },
  watch: {
    credentialCanonical () {
      this.getRepoBranches()
    },
    gitUrl () {
      this.getRepoBranches()
    },
  },
  async mounted () {
    await this.getRepoBranches()
  },
  methods: {
    async getRepoBranches () {
      const { orgCanonical, credentialCanonical, gitUrl, value } = this

      const canGetRepoBranches = checksPass({
        hasGitUrl: !_.$isEmpty(gitUrl),
        hasCredentialIfGitType: (!_.$isEmpty(credentialCanonical) && (_.startsWith(gitUrl, 'git@') || _.startsWith(gitUrl, 'ssh'))) || _.startsWith(gitUrl, 'https'),
      })

      if (!canGetRepoBranches) return

      this.loading = true
      this.errorsFromAPI = null

      const { data = [], errors } = await this.$cycloid.ydAPI.getRepoBranches(orgCanonical, credentialCanonical, gitUrl) || {}

      this.errorsFromAPI = errors
      this.branchesFromAPI = data.sort()
      this.loading = false

      if (errors || _.isEmpty(data)) return // to avoid overwriting this.value, which contains the default value on first load
      if (_.isEmpty(value) || !data.includes(value)) this.$emit('input', _.first(this.branchesFromAPI))
    },
  },
  i18n: {
    messages: {
      en: {
        errorsFromAPI: 'Error retrieving data',
        notAvailable: 'Not available',
        selectSourceInput: 'Please, select the source input first.',
      },
      es: {
        errorsFromAPI: 'Error al recuperar los datos',
        notAvailable: 'No disponible',
        selectSourceInput: 'Por favor, seleccione la fuente de input primero.',
      },
      fr: {
        errorsFromAPI: 'Erreur pendant de la récupération des données',
        notAvailable: 'Indisponible',
        selectSourceInput: `Veuillez d'abord sélectionner l'entrée source.`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.cy-inputs-branches {
  overflow: visible;

  ::v-deep {
    .v-text-field__details {
      min-width: 500px;
    }
  }
}
</style>
