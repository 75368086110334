import { helpers } from 'vuelidate/lib/validators'

const checkExistence = (item, elem) => {
  if (_.isString(item) || _.isNumber(item) || _.isBoolean(item)) {
    return item === elem
  } else if (_.isArray(item)) {
    return _.isArray(elem) && _.isEqual(item, elem)
  } else if (_.isObject(item)) {
    return _.isObject(elem) && _.isEqual(item, elem)
  }
  return false
}

const generateRuleName = (type, value) => {
  const isMustExisValidationType = ['mustExistInArray', 'mustExistKeysInMap'].includes(type)

  if (isMustExisValidationType) {
    return `${type}_${_.castArray(value).join('_')}`
  } else if (['minArrayLength', 'maxArrayLength'].includes(type)) {
    return `${type}_${value}`
  }

  return type
}

const minArrayLength = (min) => helpers.withParams(
  { type: 'minArrayLength', min },
  (value) => _.isArray(value) && value.length >= min,
)

const maxArrayLength = (max) => helpers.withParams(
  { type: 'maxArrayLength', max },
  (value) => _.isArray(value) && value.length <= max,
)

const mustExistInArray = (items) => helpers.withParams(
  { type: 'mustExistInArray', items },
  (value) => {
    if (!_.isArray(value)) return false

    return _.every(_.castArray(items), (elem) =>
      _.some(value, (item) => checkExistence(item, elem)),
    )
  },
)

const mustExistKeysInMap = (keys) => helpers.withParams(
  { type: 'mustExistKeysInMap', keys },
  (value) => {
    const keysArray = _.castArray(keys)

    if (_.isArray(value)) {
      return value.every((val) => {
        return _.isObject(val) && keysArray.every((key) => key in val)
      })
    }

    return _.isString(value)
      ? _.castArray(keys).every((key) => _.isEqual(key, value))
      : _.castArray(keys).every((key) => key in value)
  },
)

const arrayWhenMultiselect = (values) => helpers.withParams(
  { type: 'arrayWhenMultiselect' },
  (value) => {
    if (!_.isArray(value)) return false
    if (_.isUndefined(value)) return true

    return value.every((item) => _.some(values, (validItem) => _.isEqual(item, validItem)))
  },
)

export {
  minArrayLength,
  maxArrayLength,
  mustExistInArray,
  mustExistKeysInMap,
  generateRuleName,
  arrayWhenMultiselect,
}
