<template>
  <div
    data-cy="env-config"
    class="d-flex flex-column fill-height">
    <h2
      v-if="isConfigEmpty && !hasPipelineErrors"
      class="text-center ma-6">
      <v-icon color="error">
        error
      </v-icon>
      <span>{{ $t('invalidConfig') }}</span>
    </h2>

    <CyAlert
      theme="error"
      :content="configErrors"/>
    <CyAlert
      theme="warning"
      :content="configWarnings"/>

    <v-row
      v-if="localConfig && Object.keys(localConfig).length > 0 && !isConfigEmpty"
      :class="['elevation-2 code-container', {
        'expanded': (isExpanded && !relativeHeight),
        'expanded-relative': (isExpanded && relativeHeight),
      }]"
      no-gutters>
      <v-col
        cols="3"
        class="grey darken-3 list-container">
        <v-list dark>
          <template v-for="(group, groupName, index) in localConfig">
            <div
              v-if="configGroupHasFiles(localConfig, groupName)"
              :key="index">
              <v-subheader class="uppercase">
                {{ groupName }}
              </v-subheader>
              <v-list-item
                v-for="(file, fileName) in group"
                :key="fileName"
                :class="{ 'selected': isSelected(groupName, fileName) }"
                @click="!isProcessingContent && selectFile(groupName, fileName)">
                <v-list-item-content>
                  <v-list-item-title class="text-right">
                    {{ fileName }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <CyTooltip
                    v-if="isContentInvalid(groupName, fileName)"
                    top>
                    <template #activator="{ on: errorTooltip }">
                      <v-btn
                        icon
                        v-on="errorTooltip">
                        <v-icon
                          color="error"
                          class="ma-0">
                          error
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('invalidSyntax') }}</span>
                  </CyTooltip>
                  <span
                    v-else-if="isProcessingContent && isSelected(groupName, fileName)"
                    class="loader-dots loader-dots--light"/>
                  <v-icon
                    v-else
                    :color="isContentDirty(groupName, fileName)? 'light-blue darken-3': 'grey lighten-1' ">
                    code
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
              <v-divider v-if="index !== Object.keys(localConfig).length -1"/>
            </div>
          </template>
        </v-list>
      </v-col>

      <v-col cols="9">
        <CyCodeEditor
          v-model="selectedFileContent"
          :action-btn-icon="isExpanded ? 'fullscreen_exit' : 'fullscreen'"
          :action-btn-tooltip="isExpanded ? $t('minimizeEditor') : $t('maximizeEditor')"
          :debounce="500"
          :code-lang="currentCodeLang"
          @action-btn-clicked="toggleExpanded"
          @content-processed="onContentProcessed"
          @processing-content="onProcessingContent"
          @input="checkFile(selection)"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CyCodeEditor from '@/components/code-editor.vue'
import ENV_CONFIG_SCHEMA from '@/utils/config/env-config-schema'
import REGEX from '@/utils/config/regex'
import * as yaml from 'js-yaml'
import 'brace/mode/yaml'

const ENV_REGEX = '\\(\\$\\s*environment\\s*\\$\\)'
const PROJ_REGEX = '\\(\\$\\s*project\\s*\\$\\)'
const ORG_REGEX = '\\(\\$\\s*organization_canonical\\s*\\$\\)'
const CI_TEAM_NAME_REGEX = '\\(\\$\\s*ci_team_name\\s*\\$\\)'

export default {
  name: 'CyWizardEnvConfig',
  components: {
    CyCodeEditor,
  },
  props: {
    projectCanonical: {
      type: String,
      default: '',
    },
    config: {
      type: Object,
      default: null,
    },
    environment: {
      type: String,
      default: '',
    },
    displayExpanded: {
      type: Boolean,
      default: false,
    },
    relativeHeight: {
      type: Boolean,
      default: false,
    },
    hasPipelineErrors: {
      type: Boolean,
      default: false,
    },
  },
  data: ({ displayExpanded }) => ({
    isProcessingContent: false,
    selection: {
      group: null,
      file: null,
    },
    localConfig: null,
    fileMeta: {},
    isConfigEmpty: false,
    errors: {},
    warnings: {},
    isExpanded: displayExpanded,
  }),
  computed: {
    ...mapState('organization', {
      ciTeamName: (state) => state.detail?.ci_team_name,
    }),
    $static: () => ({
      excludedProperties: ['name', 'description', 'forms', 'cloud_provider', '_cloudProvider'],
    }),
    variables () {
      return [
        {
          name: ENV_REGEX,
          value: this.environment,
        },
        {
          name: PROJ_REGEX,
          value: this.projectCanonical,
        },
        {
          name: ORG_REGEX,
          value: this.orgCanonical,
        },
        {
          name: CI_TEAM_NAME_REGEX,
          value: this.ciTeamName,
        },
      ]
    },
    isAnyDirty () {
      return !_.isEmpty(_.toArray(this.fileMeta).filter((file) => file.dirty))
    },
    isContentValid () {
      return !_.some(this.fileMeta, ['invalid', true])
    },
    isValid () {
      return this.isContentValid && !this.isConfigEmpty
    },
    currentCodeLang () {
      return this.getFileFormat(this.selection.group)
    },
    selectedFileContent: {
      get () {
        return this.getConfigContent(this.localConfig, this.selection)
      },
      set (value) {
        // Sets the content to the local object
        this.setConfigContent(this.localConfig, this.selection, value)
        // Emits the new configuration object, so the parent will update the v-model object
        this.$emit('input', this.localConfig)
      },
    },
    configErrors () {
      const details = _.values(this.errors).filter((error) => !_.isEmpty(error))
      if (_.isEmpty(details)) return []

      return [{ code: 'config-errors', message: this.$t('yamlException'), details }]
    },
    configWarnings () {
      const details = _.values(this.warnings).filter((warning) => !_.isEmpty(warning))
      if (_.isEmpty(details)) return []

      return [{ code: 'config-warning', message: this.$t('warningSyntax'), details }]
    },
  },
  watch: {
    config: {
      handler (config) {
        this.processConfiguration(config)
      },
      deep: true,
      immediate: true,
    },
    isValid: {
      handler (isValid) {
        this.$emit('valid', isValid)
      },
      immediate: true,
    },
    isAnyDirty: {
      handler (isAnyDirty) {
        this.$emit('dirty', isAnyDirty)
      },
      immediate: true,
    },
  },
  methods: {
    processConfiguration (config) {
      this.clearErrors()
      // Replaces al the variables in the config file
      this.localConfig = this.replaceAllVariables(config)
      // This variable is needed to compare the local modifs and check if the file was modified
      this.originalConfig = JSON.parse(JSON.stringify(this.localConfig))
      // Select the first file with content
      const firstFile = this.getFirstFileWithContent(this.localConfig)
      this.selectFile(_.get(firstFile, 'group', null), _.get(firstFile, 'file', null))
      if (!firstFile) {
        this.isConfigEmpty = true
        return
      }

      this.checkAllFiles()
      // Notifies the parent, bound with v-model
      this.$emit('input', this.localConfig)
      this.isConfigEmpty = false
    },
    getFileFormat (group) {
      return group === 'terraform' ? 'hcl' : 'yaml'
    },
    getFirstFileWithContent (config) {
      if (_.isEmpty(config)) return null
      for (const group of Object.keys(config)) {
        for (const file of Object.keys(config[group])) {
          if (this.hasConfigContent(config, { group, file })) return { group, file }
        }
      }
      return null
    },
    configGroupHasFiles (config, group) {
      return Object.keys(config[group]).length > 0
    },
    hasConfigContent (config, { group, file }) {
      return !_.isEmpty(_.get(config, `${group}.${file}`))
    },
    getConfigContent (config, { group, file }) {
      return this.hasConfigContent(config, { group, file })
        ? config[group][file].content
        : ''
    },
    setConfigContent (config, { group, file }, content) {
      this.$set(config[group][file], 'content', content)
    },
    isSelected (group, file) {
      return this.selection.group === group && this.selection.file === file
    },
    selectFile (group, file) {
      this.selection = { group, file }
    },
    isContentDirty (group, file) {
      return _.get(this.fileMeta[`${group}.${file}`], 'dirty', false)
    },
    isContentInvalid (group, file) {
      return _.get(this.fileMeta[`${group}.${file}`], 'invalid', false)
    },
    validateFile ({ group, file }) {
      const format = this.getFileFormat(group)
      let isValid = true
      if (format === 'yaml') isValid = this.validateYamlFile(group, file)
      else if (format === 'hcl') isValid = true // TODO: FE#2790 implement hcl validation

      return isValid
    },
    validateYamlFile (group, file) {
      const filename = `${group}/${file}`
      try {
        const yamlContent = this.getConfigContent(this.localConfig, { group, file })
        this.$set(this.warnings, filename, null)
        if (REGEX.STACK_TEMPLATE.test(yamlContent)) {
          this.$set(this.warnings, filename, filename)
          return true
        }
        // ENV_CONFIG_SCHEMA was implemented to remove yaml validation errors
        // from the editor when using YAML custom types (i.e. Ansible's '!vault' type)
        // See FE#671 for more info.
        yaml.load(yamlContent, { schema: ENV_CONFIG_SCHEMA, filename })
        this.$set(this.errors, filename, null)
      } catch (error) {
        this.$set(this.errors, filename, error.message)
        return false
      }
      return true
    },
    replaceAllVariables (baseConfig) {
      if (baseConfig === null) return null
      const files = _.omit(baseConfig, this.$static.excludedProperties)
      const config = JSON.parse(JSON.stringify(files))

      for (const group in config) {
        for (const file in config[group]) {
          for (const variable of this.variables) {
            const replaceRegex = new RegExp(variable.name, 'g')
            config[group][file].content = config[group][file].content.replace(replaceRegex, variable.value)
            if (config[group][file].hasOwnProperty('destination')) {
              config[group][file].destination = config[group][file].destination.replace(replaceRegex, variable.value)
            }
          }
        }
      }
      return config
    },
    checkFile (selection) {
      const { group, file } = selection
      const originalContent = this.getConfigContent(this.originalConfig, selection)
      const invalid = !this.validateFile(selection)
      const dirty = this.getConfigContent(this.localConfig, selection) !== originalContent
      this.$set(this.fileMeta, `${group}.${file}`, { dirty, invalid })
    },
    checkAllFiles () {
      _.forEach(this.localConfig, (files, group) => {
        _.forEach(files, (content, file) => {
          this.checkFile({ group, file })
        })
      })
    },
    toggleExpanded () {
      this.isExpanded = !this.isExpanded
    },
    onProcessingContent () {
      this.isProcessingContent = true
    },
    onContentProcessed () {
      this.isProcessingContent = false
    },
    clearErrors () {
      this.errors = {}
      this.warnings = {}
    },
  },
  i18n: {
    messages: {
      en: {
        invalidConfig: 'This service has an invalid configuration. Please check the structure and content of the files.',
        invalidSyntax: 'Invalid file syntax',
        maximizeEditor: 'Expand code editor',
        minimizeEditor: 'Collapse code editor',
        yamlException: 'YAML exception',
        warningSyntax: 'The file can\'t be validated as it seems to contain templating language using ($ $) decorator:',
      },
      es: {
        invalidConfig: 'Este servicio no tiene una configuración válida. Por favor verifique la estrucutura y contenido de los ficheros.',
        invalidSyntax: 'Sintaxis no válida',
        maximizeEditor: 'Expandir editor de código',
        minimizeEditor: 'Colapso editor de código',
        yamlException: 'YAML exception',
        warningSyntax: 'El archivo no se puede validar ya que parece contener lenguaje de plantilla usando el decorador ($ $):',
      },
      fr: {
        invalidConfig: 'Ce service possède une configuration non valide. Vérifiez la strucuture et le contenu des fichiers',
        invalidSyntax: 'La syntaxe du fichier est invalide',
        maximizeEditor: `Étendre l'éditeur de code`,
        minimizeEditor: `Réduire l'éditeur de code`,
        yamlException: 'YAML exception',
        warningSyntax: 'Le fichier ne peut pas être validé car il semble contenir un langage de templating utilisant le décorateur ($ $):',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.uppercase {
  text-transform: uppercase;
}

.selected {
  background: cy-get-color("black", $alpha: 0.3);
}

.expanded {
  height: 700px;
}

.expanded-relative {
  height: 100%;
}

.v-btn .v-icon {
  margin-right: 0;
}

.list-container {
  height: 100%;
}

.code-container {
  ::v-deep .ace_gutter {
    z-index: 1 !important;
  }
}

.v-list-item:hover {
  background-color: initial;
}
</style>
