<template>
  <div>
    <div
      class="resource-input-field"
      data-cy="resource-input-field">
      <v-select
        class="cy-widget-input inventory-resource-input"
        :placeholder="value ? '' : $t('selectResource')"
        :items="selectedResources"
        :value="multiple ? _.map(selectedResources, 'id') : _.get(selectedResources, '[0].id', null)"
        item-value="id"
        item-text="name"
        append-icon="chevron_right"
        :error-messages="getErrors"
        :required="required"
        :multiple="multiple"
        readonly
        @blur="$v.selectedResources.$touch()"
        @focus="openSidePanel">
        <template #selection="{ index, item }">
          <div v-if="index === 0">
            <span v-if="multiple">
              <span v-if="value">
                {{ $tc('nSelected', value.length, { n: value.length }) }}
              </span>
            </span>
            <div
              v-else
              class="d-flex align-center">
              <div>
                <CyInventoryResourceIcon
                  :image-src="getImage(item)"
                  :is-custom="isCustomResource(item)"
                  class="mr-2"
                  data-cy="item-icon"
                  size="32"/>
              </div>
              <div class="d-flex flex-column">
                <div class="selected-resource__name">
                  {{ item.name }}
                </div>
                <div class="selected-resource__id">
                  {{ _.get(item, 'attributes.id', _.get(item, 'custom_attributes.id', '')) }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #prepend-inner>
          <div v-if="!multiple && value && _.isEmpty(selectedResources)">
            <div class="selected-resource__attribute">
              {{ value }}
            </div>
          </div>
          <div v-else-if="multiple && !_.isEmpty(value) && _.isEmpty(selectedResources)">
            <div class="selected-resource__attribute">
              {{ $tc('nSelected', value.length, { n: value.length }) }}
            </div>
          </div>
        </template>
      </v-select>
    </div>
    <portal
      v-if="sidePanelOpen"
      to="side-panel">
      <div class="side-panel-backdrop"/>
      <v-slide-x-reverse-transition>
        <v-card
          v-click-outside="{
            handler: closeSidebar,
            include: getClickOutsideExceptions,
          }"
          data-cy="inventory-resource-side-panel"
          class="side-panel">
          <div class="side-panel__header d-flex pa-6 pb-4">
            <div class="flex-grow-1">
              <div class="side-panel__title">
                {{ name }}
              </div>
              <div
                v-if="description"
                class="side-panel__description">
                {{ description }}
              </div>
            </div>
            <div class="flex-shrink-0">
              <v-icon
                data-cy="panel-close-button"
                @click="closeSidebar">
                close
              </v-icon>
            </div>
          </div>
          <v-card-text class="px-6 flex-grow-1 cy-scrollbars">
            <CyDataTableYdApi
              ref="resourceTable"
              v-model="selectedResources"
              :fetch-available="{ keyPath: 'inventoryResources' }"
              :working-route="workingRoute"
              :headers="$static.headers"
              :searchable-fields="$static.searchableFields"
              :custom-search-placeholder="$t('inventory.filterByKeyword')"
              :immediate="false"
              :prefilters="prefilterQueryObject"
              :parse-items-array="excludeResourcesWithoutRequestedAttribute"
              data-cy="resources-table"
              elevation="0"
              class="resource-table"
              key-field="id"
              :bulk="multiple"
              :single-select="!multiple">
              <template slot="table-cmp-header-filters">
                <CyFilterTree
                  :filter-tree="filters"
                  :value="selectedFilters"
                  @input="setFilterValues"/>
              </template>
              <template slot="cy-table-cmp-header-append">
                <div class="pb-4">
                  <span v-html="$sanitizeHtml(itemCountText)"/>
                  <CyMenu v-if="!_.isEmpty(prefilterQueryObject)">
                    <template #activator="{ on }">
                      <v-icon
                        size="16"
                        class="ml-1"
                        v-on="on">
                        info_outline
                      </v-icon>
                    </template>
                    <div class="prefilters-menu pa-4">
                      <div v-text="$t('prefilterMenu.text1')"/>
                      <div
                        class="mb-4"
                        v-text="$t('prefilterMenu.text2')"/>
                      <div
                        v-for="[tagKey, tagValue] in standardPrefilterTags"
                        :key="tagKey"
                        class="mb-2">
                        <CyTag variant="default">
                          <b class="mr-2">
                            {{ _.capitalize(tagKey) }}:
                          </b>
                          <span>
                            {{ tagValue }}
                          </span>
                        </CyTag>
                      </div>
                      <div
                        v-for="[tagKey, tagValue] in attributePrefilterTags"
                        :key="tagKey"
                        class="mb-2">
                        <CyTag variant="warning">
                          <v-icon
                            size="20"
                            class="mr-1"
                            color="warning darken-3">
                            data_object
                          </v-icon>
                          <b class="mr-2 warning--text text--darken-3">
                            {{ tagKey }}:
                          </b>
                          <span class="warning--text text--darken-3">
                            {{ tagValue }}
                          </span>
                        </CyTag>
                      </div>
                      <!-- TODO: Add links to docs and inventory once the pages have been created -->
                      <!-- <a
                        class="cy-link"
                        v-text="$t('prefilterMenu.inventoryLink')"/>
                      <a
                        class="cy-link"
                        v-text="$t('prefilterMenu.prefiltersDocs')"/> -->
                    </div>
                  </CyMenu>
                </div>
              </template>
              <template #table-cmp-body-row="{ props: { item } }">
                <td v-if="multiple"/>
                <td key="name">
                  <div class="d-flex align-center">
                    <div class="line-height-xs">
                      <CyInventoryResourceIcon
                        :image-src="getImage(item)"
                        :is-custom="isCustomResource(item)"
                        class="mr-2"
                        data-cy="item-icon"
                        size="32"/>
                    </div>
                    <div>
                      <div class="resource-row__name">
                        <CyTooltip
                          open-delay="250"
                          top>
                          <template #activator="{ on }">
                            <span v-on="on">
                              {{ item.name }}
                            </span>
                          </template>
                          {{ item.name }}
                        </CyTooltip>
                      </div>
                      <div class="resource-row__id">
                        <CyTooltip
                          open-delay="250"
                          top>
                          <template #activator="{ on }">
                            <span v-on="on">
                              {{ _.get(item, 'attributes.id', null) }}
                            </span>
                          </template>
                          {{ _.get(item, 'attributes.id', null) }}
                        </CyTooltip>
                      </div>
                    </div>
                  </div>
                </td>
                <td key="type">
                  <div class="resource-row__type">
                    <CyTooltip
                      open-delay="250"
                      top>
                      <template #activator="{ on }">
                        <span v-on="on">
                          {{ item.type }}
                        </span>
                      </template>
                      {{ item.type }}
                    </CyTooltip>
                  </div>
                </td>
                <td key="project">
                  <div class="resource-row__project">
                    <CyTooltip
                      open-delay="250"
                      top>
                      <template #activator="{ on }">
                        <span v-on="on">
                          {{ item.project_canonical }}
                        </span>
                      </template>
                      {{ item.project_canonical }}
                    </CyTooltip>
                  </div>
                </td>
                <td key="environment">
                  <div class="resource-row__environment">
                    <CyTooltip
                      open-delay="250"
                      top>
                      <template #activator="{ on }">
                        <span v-on="on">
                          {{ item.environment_canonical }}
                        </span>
                      </template>
                      {{ item.environment_canonical }}
                    </CyTooltip>
                  </div>
                </td>
                <td key="goToDetail">
                  <router-link
                    data-cy="resource-detail-link"
                    :to="{ name: 'inventory', query: { resourceId: item.id, resourceTab: 'details' } }"
                    target="_blank">
                    <v-icon color="primary">
                      open_in_new
                    </v-icon>
                  </router-link>
                </td>
              </template>
              <template #table-cmp-no-data>
                <div class="d-flex flex-column align-center justify-center no-resources">
                  <v-icon
                    size="32"
                    class="no-resources__icon">
                    dns
                  </v-icon>
                  <div
                    class="no-resources__title"
                    v-text="$t('noResources.title')"/>
                  <div
                    class="no-resources__subtitle"
                    v-text="$t('noResources.subtitle')"/>
                </div>
              </template>
            </CyDataTableYdApi>
          </v-card-text>
          <v-card-actions class="d-flex justify-space-between px-6 py-4">
            <CyButton
              v-if="!_.isEmpty(selectedResources)"
              variant="tertiary"
              theme="grey"
              @click="clearSelection">
              {{ $t('clearSelection') }}
            </CyButton>
            <div>
              <CyButton
                v-if="!_.isEmpty(selectedResources)"
                icon="check"
                @click="confirmSelection">
                {{ $t('confirmSelection') }}
              </CyButton>
            </div>
          </v-card-actions>
        </v-card>
      </v-slide-x-reverse-transition>
    </portal>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import CyDataTableYdApi from '@/components/data-table-yd-api.vue'
import CyFilterTree from '@/components/filter-tree.vue'
import CyInventoryResourceIcon from '@/components/inventory/resource-icon.vue'
import { FILTER_KEY_MATCHES, PREFILTER_KEY_MATCHES } from '@/utils/config/inventory-filters'
import { requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'CyFormsWidgetInventoryResource',
  components: {
    CyDataTableYdApi,
    CyInventoryResourceIcon,
    CyFilterTree,
  },
  props: {
    description: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'string',
    },
    config: {
      type: Object,
      default: () => {},
    },
    dependencies: {
      type: Object,
      default: () => {},
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Boolean, Number, Array],
      default: null,
    },
    resourceValues: {
      type: [Object, Array],
      default: null,
    },
  },
  validations: {
    selectedResources: {
      required: requiredIf(function () {
        return this.required
      }),
    },
  },
  data: () => ({
    sidePanelOpen: false,
    selectedResources: [],
    totalItemCount: 0,
  }),
  computed: {
    ...mapGetters('organization/inventory', [
      'getImage',
      'isCustomResource',
    ]),
    ...mapGetters('layout', [
      'getDataTableFilters',
    ]),
    ...mapState('organization', {
      filters: (state) => state.filters.inventoryResources,
      resources: (state) => state.available.inventoryResources,
      fetchInProgress: (state) => state.fetchInProgress.inventoryResources,
    }),
    $static () {
      return {
        headers: [
          {
            value: 'id',
            align: 'left',
            sortable: false,
          },
          {
            text: this.$t('name'),
            value: 'name',
            align: 'left',
          },
          {
            text: this.$t('forms.type'),
            value: 'type',
            align: 'left',
          },
          {
            text: this.$t('Project'),
            value: 'project_canonical',
            align: 'left',
          },
          {
            text: this.$t('Environment'),
            value: 'environment_canonical',
            align: 'left',
          },
          {
            value: '',
            align: 'left',
            sortable: false,
          },
        ],
        searchableFields: [
          {
            name: 'name',
            label: this.$t('name'),
          },
          {
            name: 'type',
            label: this.$t('forms.type'),
          },
          {
            name: 'project_canonical',
            label: this.$t('Project'),
          },
          {
            name: 'environment_canonical',
            label: this.$t('Environment'),
          },
        ],
        FILTER_KEY_MATCHES,
        PREFILTER_KEY_MATCHES,
      }
    },
    workingRoute () {
      return this.$router.resolve({ name: 'inventory' }).route
    },
    multiple () {
      return this.type === 'array'
    },
    selectedFilters () {
      const filters = this.getDataTableFilters(this.workingRoute.name)
      const categoryArrays = _.toPairs(filters).map(([key, value]) => {
        const filterKey = _.invert(this.$static.FILTER_KEY_MATCHES)[key.split('[')[0]]
        return value.split(',').map((it) => `${filterKey}.${it}`)
      })
      return _.concat(...categoryArrays)
    },
    prefilterQueryObject () {
      const standardPrefiltersObject = this.generateStandardPrefiltersObject()
      const attributePrefiltersObject = this.generateAttributePrefiltersObject()

      const allPrefilters = { ...standardPrefiltersObject, ...attributePrefiltersObject }

      return this.populatePrefilterDynamicValues(allPrefilters)
    },
    standardPrefilterTags () {
      const standardPrefilters = _.omit(this.config?.filters, ['attributes', 'custom_attributes'])
      const nonEmptyPrefilters = _.omitBy(standardPrefilters, _.isEmpty)
      const populatedPrefilters = this.populatePrefilterDynamicValues(nonEmptyPrefilters)
      const formattedPrefilters = _.mapValues(populatedPrefilters, (value) => value.replace(',', ', '))
      return _.toPairs(formattedPrefilters)
    },
    attributePrefilterTags () {
      const attributePrefilters = _.pick(this.config?.filters, ['attributes', 'custom_attributes'])
      const nonEmptyPrefilters = _.omitBy(attributePrefilters, _.isEmpty)
      const populatedPrefilters = this.populatePrefilterDynamicValues(nonEmptyPrefilters)
      const allQueries = _.values(populatedPrefilters).reduce((acc, prefilterQuery) => {
        return [...acc, ...prefilterQuery.split('&')]
      }, [])
      const prefilterPairs = allQueries.map((queryString) => {
        const [key, value] = queryString.split('=')
        const keyString = key.replace(/\[[^\]]*\]$/, '')
        return [keyString, value]
      })
      return prefilterPairs
    },
    itemCountText () {
      const currentItemCount = this.$refs.resourceTable?.filteredItemsTableData?.length
      const { totalItemCount, fetchInProgress, selectedResources } = this
      if (fetchInProgress) return ''
      if (!_.isEmpty(selectedResources)) {
        const ofNItemsText = this.$tc('inventory.ofNItems', currentItemCount, { n: currentItemCount })
        return `<b>${selectedResources.length}</b> ${ofNItemsText} ${_.lowerFirst(this.$t('selected'))}`
      }
      if (currentItemCount !== totalItemCount) {
        const nResultsText = this.$tc('inventory.nResults', currentItemCount, { n: currentItemCount })
        const ofNItemsText = this.$tc('inventory.ofNItems', totalItemCount, { n: totalItemCount })
        return `${nResultsText} ${ofNItemsText}`
      }
      return this.$tc('inventory.nItems', currentItemCount, { n: currentItemCount })
    },
    getErrors () {
      const errors = []
      const { $dirty, required: $vRequired } = this.$v.selectedResources
      if ($dirty && !$vRequired && this.required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
  },
  watch: {
    type () {
      this.clearSelection()
    },
    resources (resources) {
      const resourcesWithRequiredAttribute = this.excludeResourcesWithoutRequestedAttribute(resources)
      if (resourcesWithRequiredAttribute.length > this.totalItemCount) this.totalItemCount = resourcesWithRequiredAttribute.length
    },
    resourceValues: {
      handler (newValue, oldValue) {
        if (_.isEqual(newValue, oldValue)) return
        if (newValue === null) {
          this.selectedResources = []
          return
        }
        this.selectedResources = this.type === 'array' ? newValue : [newValue]
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations('layout', [
      'SET_DATA_TABLE_FILTERS',
    ]),
    async emitSelection () {
      await this.$nextTick()
      if (_.isEmpty(this.selectedResources)) {
        this.$emit('input', null)
        this.$emit('update-resource-id', null)
        return
      }
      const mappedResources = this.selectedResources.map((resource) => {
        const identifierAttribute = _.get(resource.attributes, this.config.attribute)
        const identifierCustomAttribute = _.get(resource.custom_attributes, this.config.attribute)
        return identifierAttribute || identifierCustomAttribute
      })
      const emitValue = this.type === 'array' ? mappedResources : _.head(mappedResources)
      const stringifiedResourceIds = _.map(this.selectedResources, 'id').map(_.toString)
      const resourceIds = this.type === 'array' ? stringifiedResourceIds : _.head(stringifiedResourceIds)
      this.$emit('input', emitValue)
      this.$emit('update-resource-id', resourceIds)
    },
    clearSelection () {
      this.$refs.resourceTable?.clearSelection()
      this.emitSelection()
    },
    closeSidebar (e) {
      const eventTargetClassName = _.get(e, 'target.className', null)
      if (eventTargetClassName === 'app-container') return
      this.sidePanelOpen = false
      this.totalItemCount = 0
      this.emitSelection()
    },
    getClickOutsideExceptions () {
      const selectors = [
        '.inventory-resource-input .v-input__slot',
        '.inventory-resource-input .v-select__selection',
        '.main-nav a',
        '.main-nav button',
        '.dev-locale-switcher__options',
        '.dev-layer',
        '.v-menu__content',
      ]
      return Array.from(document.querySelectorAll(selectors.join(', ')))
    },
    setFilterValues (values) {
      const filters = _.reduce(values, (acc, it) => {
        const [category, value] = it.split(/\.(.*)/s)
        const filterKey = `${this.$static.FILTER_KEY_MATCHES[category]}[in]`
        if (!acc[filterKey]) acc[filterKey] = value
        else acc[filterKey] = `${acc[filterKey]},${value}`
        return acc
      }, {})
      this.SET_DATA_TABLE_FILTERS({ name: 'inventory', filters })
    },
    async openSidePanel () {
      this.totalItemCount = 0
      this.sidePanelOpen = true
      await this.$nextTick()
      this.$refs.resourceTable?.retrieveItems({ updateFilters: true })
    },
    generateStandardPrefiltersObject () {
      const standardPrefilters = _.omit(this.config?.filters, ['attributes', 'custom_attributes'])
      return _.toPairs(standardPrefilters).reduce((filterObject, [prefilterKey, prefilterStringValue]) => {
        if (_.isEmpty(prefilterStringValue)) return filterObject

        const filterKey = this.$static.PREFILTER_KEY_MATCHES[prefilterKey]
        const filterQueryKey = `${this.$static.FILTER_KEY_MATCHES[filterKey]}[in]`

        filterObject[filterQueryKey] = prefilterStringValue
        return filterObject
      }, {})
    },
    generateAttributePrefiltersObject () {
      const attributePrefilters = _.pick(this.config?.filters, ['attributes', 'custom_attributes'])
      const fromPairs = _.fromPairs
      return _.toPairs(attributePrefilters).reduce((filterObject, [_, attributeQueryString]) => {
        const perAttributeQueries = attributeQueryString.split('&')
        const pairs = perAttributeQueries.map((queryString) => queryString.split('='))
        const queryObject = fromPairs(pairs)
        return { ...filterObject, ...queryObject }
      }, {})
    },
    confirmSelection () {
      this.sidePanelOpen = false
      this.emitSelection()
    },
    excludeResourcesWithoutRequestedAttribute (resources) {
      return resources.filter((resource) => {
        const attribute = _.get(resource.attributes, this.config.attribute)
        const customAttribute = _.get(resource.custom_attributes, this.config.attribute)
        return attribute || customAttribute
      })
    },
    populatePrefilterDynamicValues (prefilters) {
      return _.mapValues(prefilters, (value) => {
        const templateVariables = value.match(/\${([^}]*)}/g)
        if (!templateVariables) return value

        const replacedValue = templateVariables.reduce((acc, variable) => {
          const variableName = variable.replace('${', '').replace('}', '')
          const variableValue = this.dependencies[variableName]
          return acc.replace(variable, variableValue)
        }, value)

        return replacedValue
      })
    },
  },
  i18n: {
    messages: {
      en: {
        clearSelection: 'Clear selection',
        confirmSelection: 'Confirm selection',
        nSelected: '{n} selected',
        selectResource: 'Select resource',
        noResources: {
          title: 'No resources found',
          subtitle: 'There are no resources that match the current criteria.',
        },
        prefilterMenu: {
          text1: 'Some pre-filters are applied to the list of resources to select from.',
          text2: 'Resources selected in this field must satisfy the following criteria.',
          inventoryLink: 'View this query in the inventory',
          prefiltersDocs: 'Learn more about resource pre-filters',
        },
        selected: 'Selected',
      },
      es: {
        clearSelection: 'Borrar la selección',
        confirmSelection: 'Confirmar la selección',
        nSelected: '{n} seleccionado | {n} seleccionados',
        selectResource: 'Seleccionar recurso',
        noResources: {
          title: 'Ningún recurso encontrado',
          subtitle: 'No hay recursos que coincidan con los criterios actuales.',
        },
        prefilterMenu: {
          text1: 'Se aplican algunos filtros previos a la lista de recursos para seleccionar.',
          text2: 'Los recursos seleccionados en este campo deben satisfacer los siguientes criterios.',
          inventoryLink: 'Ver esta consulta en el inventario',
          prefiltersDocs: 'Obtenga más información sobre los prefiltros de recursos',
        },
        selected: 'Seleccionados',
      },
      fr: {
        clearSelection: 'Effacer la sélection',
        confirmSelection: 'Confirmer la sélection',
        nSelected: '{n} sélectionné | {n} sélectionnés',
        selectResource: 'Sélectioner des resources',
        noResources: {
          title: 'Aucune ressource trouvée',
          subtitle: `Il n'y a aucune ressource qui correspond aux critères donnés.`,
        },
        prefilterMenu: {
          text1: 'Certains préfiltres sont appliqués à la liste de resources.',
          text2: 'Les ressources sélectionées dans ce champ doivent satisfaire les critères suivants',
          inventoryLink: `Visualiser cette requête dans l'inventaire`,
          prefiltersDocs: 'En apprendre plus sur les préfiltres de ressources',
        },
        selected: 'Sélectionés',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.prefilters-menu {
  width: 400px;

  .tag {
    max-width: 360px;

    > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.resource-input-field {
  width: 384px;

  ::v-deep .v-input__control {
    .v-input__prepend-inner {
      align-self: center;
      margin-top: 0;

      > span {
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-break: anywhere;
      }
    }

    .v-input__slot {
      height: 56px;
      padding: 8px;

      .selected-resource {
        &__name,
        &__id,
        &__attribute {
          max-width: 298px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-break: anywhere;
        }

        &__name {
          font-size: $font-size-default;
        }

        &__id {
          color: cy-get-color("primary", "light-2");
          font-size: $font-size-sm;
        }
      }
    }
  }
}

.side-panel {
  $offset: 8px;

  display: flex;
  position: fixed;
  z-index: 110;
  top: $offset;
  right: $offset;
  flex-direction: column;
  width: 830px;
  height: calc(100% - #{$offset} * 2);

  &-backdrop {
    position: fixed;
    z-index: 108;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0 0 0 / 0.5);
  }

  &__header {
    border-bottom: 1px solid cy-get-color("grey", "light-2");
  }

  &__title {
    color: cy-get-color("primary", "dark-1");
    font-size: $font-size-h3;
    font-weight: $font-weight-bolder;
    line-height: 150%;
  }

  &__description {
    line-height: 150%;
  }

  .resource-table {
    ::v-deep .cy-table-data__row > td {
      border-bottom: none !important;
    }

    ::v-deep .cy-table-cmp-header,
    ::v-deep .cy-table-cmp-header-tags {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    .resource-row {
      &__id {
        color: cy-get-color("primary", "light-2");
        font-size: $font-size-sm;
      }

      &__id,
      &__name {
        max-width: 155px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &__type,
      &__project,
      &__environment {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-break: anywhere;
      }

      &__type {
        max-width: 128px;
      }

      &__project,
      &__environment {
        max-width: 80px;
      }
    }

    .no-resources {
      min-height: 500px;

      &__icon {
        color: cy-get-color("primary", "light-3");
      }

      &__title {
        margin-top: 24px;
        margin-bottom: 8px;
        color: cy-get-color("primary");
        font-size: $font-size-lg;
        font-weight: $font-weight-bolder;
      }

      &__subtitle {
        color: cy-get-color("primary");
      }
    }
  }
}
</style>
