import * as yaml from 'js-yaml'

// based on a custom types example from the js-yaml lib repo
// https://github.com/nodeca/js-yaml/blob/master/examples/custom_types.js

class Vault {
  constructor (data) {
    this.klass = 'Vault'
    this.data = data
  }
}

const VaultYamlType = new yaml.Type('!vault', {
  kind: 'scalar',
  resolve: (data) => typeof data === 'string',
  construct: (data) => new Vault(data),
  instanceOf: Vault,
  represent: (vault) => vault.data,
})

const ENV_CONFIG_SCHEMA = yaml.DEFAULT_SCHEMA.extend([VaultYamlType])

export default ENV_CONFIG_SCHEMA
