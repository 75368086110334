<template>
  <div>
    <v-data-table
      :headers="$static.headers"
      :items="filteredItems"
      :loading="_.isNil(pricingData)"
      :loading-text="''"
      :no-data-text="$t('noDataFound')"
      :items-per-page="filteredItems.length"
      hide-default-footer
      item-key="environment"
      class="pricing-table">
      <template #top>
        <v-row
          align="center"
          class="cy-table-cmp-header pa-4"
          no-gutters>
          <transition
            name="slide-fade-left"
            mode="out-in">
            <v-col
              v-if="searchActive"
              key="search"
              class="cy-table-cmp-header-searchbar pa-0">
              <v-text-field
                ref="headerSearchbar"
                v-model.trim="searchTerm"
                autofocus
                :placeholder="searchPlaceholder"
                append-icon="search"
                clearable
                class="search-field"
                @click:clear="$toggle.searchActive(false)"
                @blur="$toggle.searchActive(false)"/>
            </v-col>
          </transition>

          <transition name="slide-fade-right">
            <v-row
              v-if="!searchActive"
              key="filters"
              no-gutters
              class="cy-table-cmp-header-filters">
              <v-icon
                color="primary"
                data-cy="search-button"
                class="mr-6"
                @click="$toggle.searchActive">
                search
              </v-icon>
              <CyDataTableFilters
                v-if="!_.$isEmpty($static.filters)"
                class="mx-2"
                :filters="$static.filters"/>

              <CyBtnToggle
                v-model="estimateType"
                :items="$static.estimateTypes"
                class="estimate-type-toggle mr-4"
                mandatory/>
              <CyBtnToggle
                v-model="timeInterval"
                :items="$static.timeIntervals"
                class="time-interval-toggle"
                mandatory/>
            </v-row>
          </transition>
        </v-row>
      </template>
      <template #loading>
        <div class="loader">
          <v-progress-circular
            :size="24"
            color="secondary"
            indeterminate/>
        </div>
      </template>

      <template #item="{ item }">
        <tr
          v-show="pricingData.length > 1"
          class="module-row"
          @click="toggleModulePanelState(item.name)">
          <td
            align="center"
            width="19"
            class="module__panel-arrow pa-0">
            <v-icon v-if="_.get(modulePanelsState, item.name) !== undefined">
              mdi-chevron-up
            </v-icon>
            <v-icon v-else>
              mdi-chevron-down
            </v-icon>
          </td>
          <td class="module__name">
            {{ item.name }}
          </td>
          <td class="module__cost pl-8">
            {{ _.get(totals, `modules.${item.name}.cost.${isHourlyInterval ? 'hourly' : 'monthly'}`) }}
          </td>
          <td
            :class="['module__difference', {
              'diff-color--plus': _.get(totals, `modules.${item.name}.differenceSign`) === '+',
              'diff-color--minus': _.get(totals, `modules.${item.name}.differenceSign`) === '-',
            }]">
            {{ _.get(totals, `modules.${item.name}.difference.${isHourlyInterval ? 'hourly' : 'monthly'}`) }}
          </td>
        </tr>
        <tr>
          <td
            colspan="5"
            class="pa-0 panels-wrapper">
            <v-expansion-panels v-model="modulePanelsState[item.name]">
              <v-expansion-panel>
                <v-expansion-panel-content
                  v-for="(resource, index) of item.resources"
                  :key="`${resource.address}-component-${index}`"
                  class="resource"
                  eager>
                  <table>
                    <tr
                      class="resource-row"
                      @click="toggleResourcePanelState(item.name, resource.address)">
                      <td
                        class="resource-row__panel-arrow">
                        <span v-if="estimateType === 'estimated'">
                          <v-icon v-if="_.get(resourcePanelsState, `${item.name}.${resource.address}`) !== undefined">
                            mdi-chevron-up
                          </v-icon>
                          <v-icon v-else>
                            mdi-chevron-down
                          </v-icon>
                        </span>
                      </td>
                      <td class="resource__name">
                        <div class="d-flex align-center">
                          <CyInventoryResourceIcon
                            :image-src="getImage(resource)"
                            size="32"
                            class="resource-icon--side-panel"
                            data-cy="icon"/>
                          <div class="ml-2">
                            <div
                              data-cy="resource-description-summary"
                              class="item-description--primary">
                              {{ resource.type || _.upperFirst($t('unknownResourceType')) }}
                            </div>
                            <div
                              class="item-description--secondary font-size-sm">
                              {{ resource.address }}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td/>
                      <td class="resource__quantity">
                        --
                      </td>
                      <td class="resource__unit">
                        --
                      </td>
                      <td class="text-right padding-costdiff resource__cost">
                        {{ resource.cost }}
                      </td>
                      <td
                        v-if="hasPriorEstimate"
                        :class="['text-right padding-costdiff resource__difference', {
                          'diff-color--plus': resource.differenceSign === '+',
                          'diff-color--minus': resource.differenceSign === '-',
                        }]">
                        {{ resource.difference }}
                      </td>
                    </tr>
                    <tr
                      v-show="_.get(resourcePanelsState, `${item.name}.${resource.address}`)"
                      v-for="(component, idx) of resource.components"
                      :key="`${resource.address}-component-${idx}`"
                      class="component">
                      <td/>
                      <td class="component__name">
                        {{ component.label }}
                        <span
                          v-if="componentCost(component).details"
                          class="component__details">
                          ({{ componentCost(component).details.join(', ') }})
                        </span>
                      </td>

                      <td/>
                      <td class="pr-3 component__quantity">
                        {{ componentCost(component).quantity }}
                      </td>
                      <td class="pl-3 component__unit">
                        {{ componentCost(component).unit }}
                      </td>
                      <td class="padding-costdiff component__cost">
                        {{ componentCost(component).cost }}
                      </td>
                      <td
                        v-if="hasPriorEstimate"
                        class="padding-costdiff component__difference">
                        {{ componentCost(component).difference }}
                      </td>
                    </tr>
                  </table>
                </v-expansion-panel-content>
                <v-expansion-panel-content v-if="_.isEmpty(item.resources)">
                  <table>
                    <tr class="empty-module-row">
                      <td>
                        {{ $t(estimateType === 'estimated' ? 'noEstimatedInModule' : 'noUnestimatedInModule') }}
                      </td>
                    </tr>
                  </table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </td>
        </tr>
      </template>

      <template #footer>
        <v-row
          no-gutters
          class="pt-4 pb-4 pricing-table__footer">
          <v-col>
            <span class="d-flex justify-end font-weight-bold">
              {{ isHourlyInterval ? $t('terracost.hourlyTotal') : $t('terracost.monthlyTotal') }} ({{ currency }})
            </span>
            <span class="d-flex justify-end font-weight-bold mt-1">
              {{ $t('terracost.annualTotal') }} ({{ currency }})
            </span>
          </v-col>
          <v-col class="text-right padding-costdiff width-totalcostdiff">
            <span class="d-flex justify-end font-weight-bold">
              {{ isHourlyInterval ? totals.hourly : totals.monthly }}
            </span>
            <span class="d-flex justify-end  font-weight-bold mt-1">
              {{ totals.annual }}
            </span>
          </v-col>
          <v-col
            v-if="hasPriorEstimate"
            class="text-right padding-costdiff width-totalcostdiff">
            <span
              :class="['d-flex justify-end font-weight-bold', {
                'diff-color--plus': totals.differenceSign === '+',
                'diff-color--minus': totals.differenceSign === '-',
              }]">
              {{ isHourlyInterval ? totals.hourlyDiff : totals.monthlyDiff }}
            </span>
            <span class="d-flex justify-end font-weight-bold mt-1">
              {{ totals.annualDiff }}
            </span>
          </v-col>
        </v-row>
      </template>

      <template #no-data>
        <h3 class="no-estimated-resources mt-6 mb-3">
          {{ $t('noEstimatedResources.title') }}
        </h3>
        <p class="mb-0">
          {{ $t('noEstimatedResources.firstLine') }}
        </p>
        <p class="mb-6">
          <i18n path="noEstimatedResources.secondLine">
            <a
              href="https://github.com/cycloidio/terracost/issues"
              class="cy-link"
              target="_blank">{{ $t('noEstimatedResources.creatingIssue') }}</a>
            <a
              href="https://github.com/cycloidio/terracost/blob/master/CONTRIBUTING.md"
              class="cy-link"
              target="_blank">{{ $t('noEstimatedResources.contributing') }}</a>
          </i18n>
        </p>
      </template>
    </v-data-table>

    <CyAlert
      v-if="_.some($static.estimateTypes, ['key', 'unestimated'])"
      :title="$t('unestimatedNotification.title')">
      <i18n path="unestimatedNotification.message">
        <a
          href="https://github.com/cycloidio/terracost"
          class="cy-link"
          target="_blank">
          {{ $t('untranslated.terracost') }}</a>
      </i18n>
    </CyAlert>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import CyBtnToggle from '@/components/btn-toggle.vue'
import CyDataTableFilters from '@/components/data-table/filters.vue'
import CyInventoryResourceIcon from '@/components/inventory/resource-icon.vue'

export default {
  name: 'CyTerracostPricingTable',
  components: {
    CyBtnToggle,
    CyDataTableFilters,
    CyInventoryResourceIcon,
  },
  props: {
    pricingData: {
      type: Array,
      default: () => [],
    },
  },
  data: ({ pricingData }) => ({
    estimateType: 'estimated',
    searchActive: false,
    searchTerm: '',
    timeInterval: 'monthly',
    resourcePanelsState: {},
    modulePanelsState: _.reduce(pricingData, (acc, data) => ({ ...acc, [data.name]: 0 }), {}),
  }),
  computed: {
    ...mapState('organization/terracost', [
      'currency',
    ]),
    ...mapGetters('layout', [
      'getDataTableFilters',
    ]),
    ...mapGetters('organization/terracost', [
      'getAmountWithCurrency',
    ]),
    ...mapGetters('organization/inventory', [
      'getImage',
    ]),
    $static () {
      const resourceEstimates = _.concat(...this.pricingData.map((data) => _.$get(data, 'resource_estimates', [])))
      const estimated = _.sumBy(this.pricingData, (module) => this.filterBySearch(this.filterByFilters(_.$get(module, 'resource_estimates', []))).length)
      const unestimated = _.sumBy(this.pricingData, (module) => this.filterBySearch(this.filterByFilters(this.getSkippedResources(module))).length)

      const resourceTypes = _.chain(resourceEstimates)
        .filter(({ type }) => !_.isEmpty(type))
        .map(({ type }) => ({
          title: `${this.$t('forms.type')}: ${type}`,
          key: 'type',
          value: type,
        }))
        .uniqBy(({ value }) => value)
        .value()

      return {
        estimateTypes: [
          {
            key: 'estimated',
            value: 'estimated',
            theme: 'secondary',
            variant: 'secondary',
            text: this.$tc('terracost.estimated', estimated, { nb: estimated }),
          },
          ...(unestimated
            ? [{
                key: 'unestimated',
                value: 'unestimated',
                theme: 'secondary',
                variant: 'secondary',
                text: this.$tc('terracost.unestimated', unestimated, { nb: unestimated }),
              }]
            : []
          ),
        ],
        filters: [
          {
            type: 'select',
            label: _.upperFirst(this.$t('terracost.resource.type')),
            queryParams: ['type'],
            items: resourceTypes,
          },
        ],
        headers: [
          {
            text: '',
            value: '',
            filterable: false,
            sortable: false,
            width: '48px',
          },
          {
            text: this.$t('terracost.resource.text'),
            value: 'type',
            width: '400px',
          },
          {
            text: '',
            value: 'address',
            sortable: false,
          },
          {
            text: this.$t('terracost.quantity'),
            value: 'quantity',
            align: 'right',
            width: '75px',
            sortable: false,
          },
          {
            text: this.$t('terracost.unit'),
            value: 'unit',
            align: 'left',
            width: '75px',
            sortable: false,
          },
          {
            text: this.$t('terracost.cost'),
            value: 'cost',
            align: 'right',
            width: '150px',
          },
          ...(this.hasPriorEstimate
            ? [{
                text: this.$t('terracost.difference'),
                value: 'difference',
                align: 'right',
                width: '150px',
              }]
            : []
          ),
        ],
        searchableFields: [
          {
            name: 'resource',
            label: this.$t('terracost.resource.type'),
          },
          {
            name: 'component',
            label: this.$t('terracost.component'),
          },
          {
            name: 'address',
            label: this.$t('terracost.resource.name'),
          },
        ],
        timeIntervals: [
          {
            key: 'monthly',
            value: 'monthly',
            theme: 'secondary',
            variant: 'secondary',
            text: this.$t('terracost.monthly'),
          },
          {
            key: 'hourly',
            value: 'hourly',
            theme: 'secondary',
            variant: 'secondary',
            text: this.$t('terracost.hourly'),
          },
        ],
      }
    },
    hasPriorEstimate () {
      return _.some(this.pricingData || [], (data) => !_.$isEmpty(data.prior_hourly_cost) && !_.$isEmpty(data.prior_cost))
    },
    isHourlyInterval () {
      return this.timeInterval === 'hourly'
    },
    filteredItems () {
      return this.pricingData
        .map((data) => {
          const resources = this.estimateType === 'estimated'
            ? this.filterBySearch(this.filterByFilters(this.getItemsByTimeInterval(data)))
            : this.filterBySearch(this.filterByFilters(this.getSkippedResources(data)))
          return { name: data.name, resources }
        })
        .filter(({ resources }) => !_.isEmpty(resources))
    },
    searchPlaceholder () {
      const searchBy = this.$t('forms.searchBy')
      const labels = _.map(this.$static.searchableFields, 'label')
      const conjunction = this.$t('or')
      const listOfLabels = _.$getListFromArray(labels, { conjunction })
      return `${searchBy} ${listOfLabels}`
    },
    totals () {
      const data = this.pricingData || []

      const sumFn = (key) => (object) => Number(object[key] || 0)

      const hourlyTotal = _.sumBy(data, sumFn('planned_hourly_cost'))
      const monthlyTotal = _.sumBy(data, sumFn('planned_cost'))
      const priorHourlyTotal = _.sumBy(data, sumFn('prior_hourly_cost'))
      const priorMonthlyTotal = _.sumBy(data, sumFn('prior_cost'))

      const MONTHS_IN_YEAR = 12

      const annualValue = this.getAmountWithCurrency(monthlyTotal * MONTHS_IN_YEAR)
      const hourlyValue = this.getAmountWithCurrency(hourlyTotal)
      const monthlyValue = this.getAmountWithCurrency(monthlyTotal)

      const annual = monthlyTotal ? annualValue : '--'
      const hourly = hourlyTotal ? hourlyValue : '--'
      const monthly = monthlyTotal ? monthlyValue : '--'

      let annualDiff = '--'
      let hourlyDiff = '--'
      let monthlyDiff = '--'

      let differenceSign

      const getDifferenceSign = (n) => {
        if (!n) return ''
        return n < 0 ? '-' : '+'
      }

      if (!_.$isEmpty(priorHourlyTotal)) {
        const diff = Number(hourlyTotal) - Number(priorHourlyTotal)
        differenceSign = getDifferenceSign(diff)

        const hourlyDiffValue = this.getAmountWithCurrency(Math.abs(diff))

        hourlyDiff = `${getDifferenceSign(diff)}${hourlyDiffValue}`
      }

      if (!_.$isEmpty(priorMonthlyTotal)) {
        const diff = Number(monthlyTotal) - Number(priorMonthlyTotal)
        differenceSign = getDifferenceSign(diff)

        const annualDiffValue = this.getAmountWithCurrency(Math.abs(diff) * MONTHS_IN_YEAR)
        const monthlyDiffValue = this.getAmountWithCurrency(Math.abs(diff))

        annualDiff = `${getDifferenceSign(diff)}${annualDiffValue}`
        monthlyDiff = `${getDifferenceSign(diff)}${monthlyDiffValue}`
      }

      const modules = this.pricingData.reduce((acc, { name, resource_estimates: resources }) => {
        const monthlyCost = _.sumBy(resources, sumFn('planned_cost'))
        const hourlyCost = _.sumBy(resources, sumFn('planned_hourly_cost'))
        const monthlyDiff = monthlyCost - _.sumBy(resources, sumFn('prior_cost'))
        const hourlyDiff = monthlyCost - _.sumBy(resources, sumFn('prior_hourly_cost'))
        acc[name] = {
          cost: {
            monthly: this.getAmountWithCurrency(monthlyCost),
            hourly: this.getAmountWithCurrency(hourlyCost),
          },
          difference: {
            monthly: `${getDifferenceSign(monthlyDiff)}${this.getAmountWithCurrency(Math.abs(monthlyDiff))}`,
            hourly: `${getDifferenceSign(hourlyDiff)}${this.getAmountWithCurrency(Math.abs(hourlyDiff))}`,
          },
          differenceSign: getDifferenceSign(monthlyDiff),
        }
        return acc
      }, {})

      return { annual, annualDiff, hourly, hourlyDiff, monthly, monthlyDiff, differenceSign, modules }
    },
  },
  methods: {
    getItemsByTimeInterval (module) {
      const { resource_estimates: resourceEstimates = [] } = module || {}

      return _.map(resourceEstimates, (resourceEstimate) => {
        const { planned_hourly_cost: hourlyCost, planned_cost: monthlyCost, prior_hourly_cost: priorHourly, prior_cost: priorMonthly } = resourceEstimate

        const costValue = this.isHourlyInterval ? hourlyCost : monthlyCost
        const priorCostValue = this.isHourlyInterval ? priorHourly : priorMonthly
        const formattedCost = this.getAmountWithCurrency(costValue)
        const costDifference = Number(costValue) - Number(priorCostValue)

        let differenceSign = ''
        if (costDifference < 0) differenceSign = '-'
        if (costDifference > 0) differenceSign = '+'

        const difference = `${differenceSign}${this.getAmountWithCurrency(Math.abs(costDifference))}`
        const cost = !_.$isEmpty(formattedCost) ? formattedCost : '--'

        return { ...resourceEstimate, cost, difference, differenceSign }
      })
    },
    getSkippedResources (module) {
      const { skipped_resource_addresses: skippedResources = [] } = module || {}

      return _.map(skippedResources, (address) => {
        const addressChunks = _.split(address, '.')
        const type = addressChunks[addressChunks.length - 2]
        return { type, address, cost: '--', difference: '--' }
      })
    },
    componentCost ({ planned = {}, prior = {}, unit = '' }) {
      const { hourly_cost: priorHourly = '0', cost: priorMonthly = '0' } = prior
      const { hourly_cost: plannedHourly, cost: plannedMonthly } = planned

      const costValue = this.isHourlyInterval ? plannedHourly : plannedMonthly
      const priorCostValue = this.isHourlyInterval ? priorHourly : priorMonthly
      const differenceValue = Math.abs(Number(costValue) - Number(priorCostValue))
      const diffSign = Number(costValue) >= Number(priorCostValue) ? '+' : '-'

      const quantity = _.get(planned, 'quantity', '')
      const details = _.get(planned, 'details')
      const cost = this.getAmountWithCurrency(costValue)
      const difference = `${diffSign}${this.getAmountWithCurrency(differenceValue)}`

      return { cost, difference, quantity, unit, details }
    },
    filterByFilters (items) {
      const { 'type[in]': typesToFilter = '' } = this.getDataTableFilters(this.$route.name)

      return items.filter(({ type }) => !_.$isEmpty(typesToFilter) ? _.includes(typesToFilter, type) : true)
    },
    filterBySearch (items) {
      const { estimateType } = this

      if (_.isEmpty(items)) return []

      return items.filter((item) => {
        for (const property of ['component', 'address', 'resource']) {
          const fieldsToSearch = _.join([
            _.get(item, property, ''),
            ...(estimateType === 'estimated' ? _.flatMap(item.components, 'label') : [item.type]),
          ], ' ')
          const matchesSearch = fieldsToSearch.toLowerCase().includes((this.searchTerm || '').toLowerCase())

          if (matchesSearch) return true
        }

        return false
      }) || []
    },
    toggleResourcePanelState (module, key) {
      const previousValue = _.get(this.resourcePanelsState, `${module}.${key}`, undefined)
      this.$set(this.resourcePanelsState, `${module}.${key}`, previousValue === undefined ? true : undefined)
    },
    toggleModulePanelState (key) {
      this.$set(this.modulePanelsState, key, this.modulePanelsState[key] === undefined ? 0 : undefined)
    },
  },
  i18n: {
    messages: {
      en: {
        module: 'Module',
        noDataFound: 'No data found',
        noEstimatedResources: {
          contributing: 'contributing',
          creatingIssue: 'creating an issue',
          firstLine: `We couldn't estimate any of the infrastructure resources.`,
          secondLine: 'You can help by {0} or by {1} to the TerraCost open source project.',
          title: 'No estimated resources',
        },
        noEstimatedInModule: 'There are no estimated resources in this module',
        noUnestimatedInModule: 'There are no unestimated resources in this module',
        unestimatedNotification: {
          message: 'Some newer resources may not be covered yet by {0}, the solution powering our cost estimations.',
          title: 'Why are there unestimated resources?',
        },
        unknownResourceType: 'Unknown @:terracost.resource.type',
      },
      es: {
        module: 'Módulo',
        noDataFound: 'Ninguna información encontrada',
        noEstimatedResources: {
          contributing: 'contribuyendo',
          creatingIssue: 'creando un issue',
          firstLine: 'No pudimos estimar ninguno de los recursos de la infraestructura.',
          secondLine: 'Puede contribuir {0} o simplemente {1} al proyecto open source TerraCost.',
          title: 'Ningún recurso estimado',
        },
        noEstimatedInModule: 'No hay recursos estimados en este módulo',
        noUnestimatedInModule: 'No hay recursos no estimados en este módulo.',
        unestimatedNotification: {
          message: 'Es posible que {0}, la solución que impulsa nuestras estimaciones de costos, aún no cubra algunos recursos más nuevos.',
          title: '¿Por qué hay recursos no estimados?',
        },
        unknownResourceType: '@:terracost.resource.type desconocido',
      },
      fr: {
        module: 'Module',
        noDataFound: 'Aucune donnée trouvée',
        noEstimatedResources: {
          contributing: 'contribuant',
          creatingIssue: 'créant une issue',
          firstLine: `Nous n'avons pu estimer aucune des ressources d'infrastructure.`,
          secondLine: 'Vous pouvez aider en {0} ou en {1} au projet open source terracost.',
          title: 'Aucune ressource estimée',
        },
        noEstimatedInModule: `Il n'y a pas de resources estimées dans ce module`,
        noUnestimatedInModule: `Il n'y a pas de resources non estimées dans ce module`,
        unestimatedNotification: {
          message: 'Certaines ressources plus récentes peuvent ne pas encore être couvertes par {0}, la solution qui alimente nos estimations de coûts.',
          title: 'Pourquoi y a-t-il des ressources non estimées ?',
        },
        unknownResourceType: '@:terracost.resource.type inconnu',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@keyframes slide-in {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
  }
}

::v-deep {
  .estimate-type-toggle,
  .time-interval-toggle {
    display: flex;
    flex: 0 1 auto;
    justify-content: flex-end;
  }

  .estimate-type-toggle {
    flex-grow: 1;
  }

  .time-interval-toggle .v-btn__content {
    color: cy-get-color("primary");
  }

  .v-progress-linear {
    display: none;
    height: 0;
  }

  .cy-alert-content {
    > .cy-alert-message {
      flex-direction: row;

      > .cy-alert-message__title--warning {
        color: cy-get-color("primary");
      }

      > .cy-alert-message__text {
        width: auto;
        padding-left: 1rem;
      }
    }
  }

  .v-data-table {
    %table-layout {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    &-header {
      @extend %table-layout;
    }

    &__wrapper {
      tbody {
        @extend %cy-scrollbars;

        max-height: 280px;
        overflow-y: scroll; // stylelint-disable-line declaration-property-value-disallowed-list

        tr {
          @extend %table-layout;
        }

        td {
          &:nth-child(4) {
            text-align: right;
          }

          &:nth-child(5) {
            text-align: left;
          }

          &:nth-child(6),
          &:nth-child(7) {
            width: 150px;
            padding: 0 30px !important;
          }

          &.resource-row {
            &__resource-address {
              color: cy-get-color("grey", "dark-2");
            }

            &__panel-arrow {
              width: 64px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

.pricing-table {
  // following three rules are because with elevation-1 it did not properly look like in mockups
  margin: 16px 0 24px;
  overflow: hidden;
  border-radius: 4px;
  box-shadow:
    0 1px 5px cy-get-color("primary", $alpha: 0.2),
    0 1px 1px cy-get-color("primary", $alpha: 0.14),
    0 1px 3px cy-get-color("primary", $alpha: 0.12);

  .padding-costdiff {
    padding-right: 30px;
  }

  .width-totalcostdiff {
    width: 150px;
  }

  .module {
    &-row {
      cursor: pointer;
    }

    &__cost {
      width: 150px;
      min-width: 150px;
      padding-right: 30px;
      text-align: right;
    }

    &__difference {
      width: 150px;
      min-width: 150px;
      padding-right: 30px;
      text-align: right;
    }
  }

  .panels-wrapper {
    height: auto;
    border-bottom: 0 !important;

    ::v-deep .v-expansion-panel-content__wrap {
      display: flex;
      align-items: center;
      padding: 0;
      background-color: cy-get-color("grey", "light-4");

      table {
        border-spacing: 0;

        tr {
          height: 48px;

          &:not(:first-child) {
            border-top: 1px solid cy-get-color("grey", "light-2");
          }
        }
      }

      .empty-module-row {
        td {
          padding-left: 63px;
          color: cy-get-color("grey", "dark-2");
        }
      }

      &:last-child {
        border-bottom: 1px solid cy-get-color("grey", "light-2");
      }

      .resource,
      .component {
        &__name {
          width: 100%;
          color: cy-get-color("grey", "dark-2");
        }

        &__quantity {
          width: 75px;
          min-width: 75px;
          color: cy-get-color("grey", "dark-2");
          text-align: right;
          white-space: nowrap;
        }

        &__unit {
          width: 75px;
          min-width: 75px;
          color: cy-get-color("grey", "dark-2");
          text-align: left;
          white-space: nowrap;
        }

        &__cost {
          min-width: 150px;
          color: cy-get-color("grey", "dark-2");
          text-align: right;
        }

        &__difference {
          min-width: 150px;
          text-align: right;
        }
      }

      .resource {
        &-row {
          background: cy-get-color("white");
          cursor: pointer;
        }

        &__quantity {
          padding-right: 12px;
        }

        &__unit {
          padding-left: 12px;
        }
      }

      .component {
        &__name {
          padding-left: 64px;
        }

        &__difference {
          color: cy-get-color("grey", "dark-2");
        }
      }
    }
  }

  .cy-table-cmp-header {
    min-height: 60px;
    margin: 0 !important;
    border-bottom: solid 1px cy-get-color("grey", "light-2");
    border-radius: 4px 4px 0 0;
    background-color: cy-get-color("white");

    &-searchbar {
      ::v-deep .v-input__slot {
        margin-bottom: 0;
      }

      ::v-deep .v-text-field {
        margin-top: 2px !important;
      }

      .input-group__details {
        height: 5px !important;
      }
    }

    &-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &-actions ::v-deep button {
      margin: 12px 8px;
    }
  }

  .diff-color {
    &--plus {
      color: cy-get-color("error", "dark-1");
    }

    &--minus {
      color: cy-get-color("success", "dark-2");
    }
  }

  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
  }

  .no-estimated-resources {
    color: cy-get-color("primary");
    font-weight: normal;
  }

  .item-description--primary {
    color: cy-get-color("primary");
  }

  .item-description--secondary {
    color: map.get($grey, "dark-2");
  }

  .slide-fade-left {
    &-enter-active {
      transform-origin: left center;
      transition: all 0.6s ease-in;
      animation: slide-in 0.6s;
    }

    &-enter {
      transform: translateX(-20px) !important;
      opacity: 0;
    }

    &-leave,
    &-leave-active {
      transition: none;
    }
  }

  .slide-fade-right {
    &-enter-active {
      transform-origin: right center;
      transition: all 0.45s ease;
    }

    &-enter {
      transform: translateX(20px) !important;
      opacity: 0;
    }

    &-leave,
    &-leave-active {
      transition: none;
    }
  }

  &__footer {
    border-top: solid 1px cy-get-color("grey", "light-2");
    background-color: cy-get-color("grey", "light-4");

    .col {
      max-width: 150px;

      &:first-child {
        width: 100%;
        max-width: unset;
      }
    }

    .d-flex:last-child {
      color: cy-get-color("grey", "dark-2");
    }
  }
}
</style>
