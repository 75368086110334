var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CyMenu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('CyButton',_vm._g(_vm._b({staticClass:"mx-4",attrs:{"icon":"add","variant":"tertiary","theme":"grey"}},'CyButton',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('addFilter'))+" ")])],1)]}}])},[_c('div',{staticClass:"filter-tree-menu",attrs:{"data-cy":"filter-tree-menu"}},[(!_vm.isTopLevel)?_c('div',{staticClass:"category-header",attrs:{"data-cy":"category-header"}},[_c('CyButton',{attrs:{"data-cy":"back-button","variant":"tertiary","theme":"grey","icon-only":"","icon":"chevron_left","aria-label":"Back"},on:{"click":_vm.backBtnHandler}}),_vm._v(" "+_vm._s(_vm.levelDepth === 1 && _vm.$te(_vm.focusedCategory) ? _vm.$t(_vm.focusedCategory) : _vm.focusedCategory)+" ")],1):_vm._e(),_c('ul',{staticClass:"filter-list pb-2",attrs:{"data-cy":"filter-list"}},[_c('v-text-field',{staticClass:"search-bar pa-2",attrs:{"data-cy":"search-bar","single-line":"","hide-details":"","filled":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',[_vm._v(" search ")]),_vm._v(" "+_vm._s(_vm.$t(_vm.searchBarLabel))+"... ")]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_vm._l((_vm.focusedSubTreeWithSearch),function(ref){
var key = ref[0];
var itemValue = ref[1];
return _c('li',{key:key,staticClass:"filter-list__item",attrs:{"aria-label":_vm.getItemLabel(key, itemValue)},on:{"click":function($event){return _vm.itemClickHandler(key)}}},[(_vm.isInFilterDepth)?_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"color":"secondary","input-value":_vm.getCheckboxValue(key),"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('CyTooltip',{attrs:{"open-delay":"250","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"filter-checkbox__label"},on),[_vm._v(" "+_vm._s(_vm.getItemLabel(key, itemValue))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.getItemLabel(key, itemValue))+" ")])]},proxy:true}],null,true)}):_c('span',[_vm._v(" "+_vm._s(_vm.getItemLabel(key, itemValue))+" ")])],1)}),(_vm._.isEmpty(_vm.focusedSubTreeWithSearch))?_c('div',{staticClass:"filter-list--empty px-4 py-1"},[_vm._v(" "+_vm._s(_vm.$t('noItemsFound'))+" ")]):_vm._e()],2),(_vm.isInFilterDepth)?_c('div',{class:['actions-footer d-flex pa-2', _vm.anyFiltersSelectedInCurrentCategory ? 'justify-space-between' : 'justify-end'],attrs:{"data-cy":"actions-footer"}},[(_vm.anyFiltersSelectedInCurrentCategory)?_c('CyButton',{attrs:{"variant":"tertiary","theme":"grey"},on:{"click":_vm.clearCategoryFilters}},[_vm._v(" "+_vm._s(_vm.$t('clearFilters'))+" ")]):_vm._e(),_c('CyButton',{attrs:{"data-cy":"apply-button"},on:{"click":_vm.applyFilters}},[_vm._v(" "+_vm._s(_vm.$t('applyFilters'))+" ")])],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }