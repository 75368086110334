<template>
  <v-text-field
    v-bind="$attrs"
    :value="value"
    single-line
    :class="['cy-widget-input', 'cy-inputs-simple-text', width]"
    :error-messages="getErrors"
    v-on="$listeners"
    @blur="$v.value.$touch()"
    @change="$emit('input', $event)"/>
</template>

<script>
import { requiredIf, helpers } from 'vuelidate/lib/validators'

export default {
  name: 'CyFormsWidgetSimpleText',
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: 'is-x-large',
    },
    value: {
      type: String,
      default: '',
    },
    formsValidations: {
      type: Array,
      default () {
        return []
      },
    },
  },
  validations () {
    if (this.formsValidations) {
      return {
        value: {
          required: requiredIf(function () {
            return this.required
          }),
          ...(this.formsValidations).reduce((validators, validation, index) => {
            if (validation.regexp) {
              const validatorName = `regexp_${index}`
              const regexp = new RegExp(validation.regexp)
              validators[validatorName] = helpers.regex(validatorName, regexp)
            }
            return validators
          }, {}),
        },
      }
    }
    return {
      value: {
        required: requiredIf(function () {
          return this.required
        }),
      },
    }
  },
  computed: {
    getErrors () {
      const errors = []
      const { $dirty, $error, required: $vRequired } = this.$v.value
      if ($dirty && !$vRequired && this.required) errors.push(this.$t('forms.fieldRequired'))
      if ($dirty && $error && this.formsValidations) {
        this.formsValidations.forEach((validation, index) => {
          const validatorName = `regexp_${index}`
          if (!_.get(this.$v.value, validatorName)) {
            errors.push(validation.error_message)
          }
        })
      }
      return errors
    },
  },
}
</script>

<style lang="scss" scoped>
$width-large: 228px;
$width-x-large: 440px;
$size-variants: (
  "is-large": $width-large,
  "is-x-large": $width-x-large
);

.cy-inputs-simple-text {
  width: $width-large;

  ::v-deep {
    .v-input__control {
      input {
        padding-right: 1rem;
        padding-left: 1rem;
      }

      .v-text-field__details {
        height: 1em;
      }
    }
  }

  @each $name, $width in $size-variants {
    &.#{$name} {
      width: $width;
    }
  }
}
</style>
