<script>
/* eslint-disable vue/no-unused-properties */
import { VRadio, VLabel } from 'vuetify/lib'

export default {
  name: 'CyInputsRadioCard',
  extends: VRadio,
  props: {
    color: {
      type: String,
      default: 'secondary',
    },
  },
  methods: {
    genLabel () {
      return this.$createElement(VLabel, {
        on: { click: this.onChange },
        attrs: {
          for: this.computedId,
        },
        props: {
          color: this.validationState,
          focused: this.hasState,
        },
      }, [
        this.genRadio(),
        this.$slots.label || this.label,
      ])
    },
  },

  render (h) {
    return this.$createElement('div', {
      staticClass: 'cy-radio-card',
      class: this.classes,
      attrs: {
        'data-cy': 'radio-card',
      },
    }, [
      this.genLabel(),
    ])
  },
}
</script>

<style lang="scss" scoped>
.cy-radio-card {
  display: flex;

  & + & {
    margin-top: $spacer-2;
  }

  .v-input--radio-group--row & {
    flex-basis: 0;
  }

  .v-input--radio-group--row & + & {
    margin-top: 0;
    margin-left: $spacer-2;
  }
}

.v-label {
  display: inline-flex;
  flex-grow: 1;
  height: auto;
  padding: $spacer-4;
  border: 1px solid cy-get-color("grey");
  border-radius: $spacer;
  background-color: cy-get-color("white");
  color: cy-get-color("primary");
  font-size: $font-size-default;
  cursor: pointer;

  .v-item--active > & {
    border-color: cy-get-color("secondary", "light-1");
    background-color: cy-get-color("secondary", "light-4");
  }

  .v-radio--is-disabled > & {
    background-color: cy-get-color("grey", $alpha: 0.2);
    cursor: not-allowed;
  }

  &:hover {
    border-color: cy-get-color("secondary");
  }

  &:focus-within {
    border-color: cy-get-color("secondary");
    box-shadow: 0 0 0 4px cy-get-color("secondary", $alpha: 0.6);
  }

  .v-input--radio-group--row & {
    align-items: start;
  }
}

.v-input--selection-controls__input {
  margin-right: $spacer-4;
}
</style>
