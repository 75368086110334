const FILTER_KEY_MATCHES = {
  environments: 'environment_canonical',
  labels: 'resources_label',
  modules: 'resources_module',
  projects: 'project_canonical',
  providers: 'resources_provider',
  types: 'resources_type',
  names: 'resources_name',
  modes: 'resources_mode',
  resourcePools: 'resource_pool_canonical',
  teams: 'team_canonical',
  organizations: 'organization_canonical',
}

const PREFILTER_KEY_MATCHES = {
  environment: 'environments',
  project: 'projects',
  label: 'labels',
  module: 'modules',
  provider: 'providers',
  type: 'types',
  name: 'names',
}

export {
  FILTER_KEY_MATCHES,
  PREFILTER_KEY_MATCHES,
}
