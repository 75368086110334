import { render, staticRenderFns } from "./service-wizard.vue?vue&type=template&id=0a49abb6&scoped=true"
import script from "./service-wizard.vue?vue&type=script&lang=js"
export * from "./service-wizard.vue?vue&type=script&lang=js"
import style0 from "./service-wizard.vue?vue&type=style&index=0&id=0a49abb6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a49abb6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VCombobox,VIcon,VListItem,VListItemContent,VListItemTitle,VSlideYTransition,VStepper,VStepperContent,VStepperStep,VSwitch})
