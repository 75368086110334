var render, staticRenderFns
import script from "./radio-card.vue?vue&type=script&lang=js"
export * from "./radio-card.vue?vue&type=script&lang=js"
import style0 from "./radio-card.vue?vue&type=style&index=0&id=834e9fdc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "834e9fdc",
  null
  
)

export default component.exports