<template>
  <v-text-field
    v-bind="$attrs"
    :value="value"
    single-line
    type="number"
    class="cy-widget-input cy-inputs-number"
    :error-messages="getErrors"
    :min="min"
    :step="step"
    @blur="$v.value.$touch()"
    @input="emitTypedValue($event)"
    @change="emitTypedValue($event)">
    <template slot="append">
      <v-icon
        class="cy-inputs-number__decrease-icon"
        @click="decrease($event)">
        remove
      </v-icon>
      <v-icon
        class="cy-inputs-number__increase-icon"
        @click="increase($event)">
        add
      </v-icon>
    </template>
  </v-text-field>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'CyFormsWidgetNumber',
  props: {
    value: {
      type: Number,
      default: undefined,
    },
    min: {
      type: Number,
      default: 0,
    },
    required: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'integer',
      validator: (value) => _.includes(['integer', 'float'], value),
    },
  },
  validations: {
    value: {
      required: requiredIf(function () {
        return this.required
      }),
    },
  },
  computed: {
    getErrors () {
      const errors = []
      const { $dirty, required: $vRequired } = this.$v.value
      if ($dirty && !$vRequired && this.required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    isInteger () {
      return this.type === 'integer'
    },
    step () {
      return this.isInteger ? 1 : 0.01
    },
  },
  mounted () {
    if (this.value === undefined) return
    if (this.value < this.min) this.$emit('input', Number(this.min))
    else this.$emit('input', Number(this.value))
  },
  methods: {
    decrease (event) {
      const input = event.target.parentElement.previousSibling.childNodes[0]
      input.stepDown()
      this.$emit('input', Number(input.value))
    },
    increase (event) {
      const input = event.target.parentElement.previousSibling.childNodes[0]
      input.stepUp()
      this.$emit('input', Number(input.value))
    },
    emitTypedValue (value) {
      if (value === '') return this.$emit('input', null)

      this.$emit('input', this.isInteger ? parseInt(Number(value)) : Number(value))
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep input::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

::v-deep input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

::v-deep input {
  appearance: textfield;
}

.cy-inputs-number {
  width: 303px;
  overflow: visible;

  &__decrease-icon {
    padding-right: 5px;
    border-right: 1px cy-get-color("grey", "light-1") solid;
  }

  &__increase-icon {
    padding-right: 5px;
    padding-left: 5px;
  }

  ::v-deep {
    .v-input__control {
      .v-text-field__details {
        min-width: 500px;
      }

      .v-input__slot {
        input {
          padding-left: 12px;
          text-align: left;
        }
      }
    }
  }
}
</style>
