<script>
/* eslint-disable vue/no-unused-properties */
import { VExpansionPanels } from 'vuetify/lib'

export default {
  name: 'CyExpansionPanel',
  extends: VExpansionPanels,
  props: {
    validatePanel: {
      type: Function,
      default: (index) => true,
    },
  },
  methods: {
    onClick (item) {
      const currentItemIndex = this.internalValue
      const isPanelValid = currentItemIndex > -1 ? this.validatePanel(currentItemIndex) : true
      if (!isPanelValid) return

      this.updateInternalValue(
        this.getValue(item, this.items.indexOf(item)),
      )
    },
  },
}
</script>
