<template>
  <div v-if="description.length > 0">
    <vue-showdown
      flavor="github"
      :options="{
        tables: true,
        noHeaderId: true,
      }"
      :markdown="markdown"/>
  </div>
</template>

<script>
export default {
  name: 'CyFormsWidgetDescription',
  props: {
    description: {
      type: String,
      default: '',
    },
  },
  computed: {
    markdown () {
      return this.$sanitizeHtml(this.description)
    },
  },
}
</script>
