import { render, staticRenderFns } from "./pricing-panel.vue?vue&type=template&id=24a03a76&scoped=true"
import script from "./pricing-panel.vue?vue&type=script&lang=js"
export * from "./pricing-panel.vue?vue&type=script&lang=js"
import style0 from "./pricing-panel.vue?vue&type=style&index=0&id=24a03a76&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24a03a76",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VSpacer})
