<template>
  <div class="sidebar">
    <div
      v-if="!_.isEmpty(providers)"
      class="sidebar__section">
      <p class="sidebar__title">
        {{ $t('providers') }}
      </p>
      <div class="d-flex">
        <CyIconCredential
          v-for="provider of providers"
          :key="provider"
          size="22"
          :type="provider"
          show-tooltip
          tooltip-direction="top"
          :tooltip-text="_.$get(getProviderExtraInfoByCredType, provider, {}).displayNameFull"/>
      </div>
    </div>

    <div
      v-if="!_.isEmpty(stack.technologies)"
      class="sidebar__section">
      <p class="sidebar__title">
        {{ $t('technologies') }}
      </p>
      <ul class="list-style-none pl-0">
        <li
          v-for="(tech, id) in stack.technologies"
          :key="id">
          {{ tech.technology }}: {{ tech.version }}
        </li>
      </ul>
    </div>

    <div
      v-if="!_.isEmpty(stack.keywords)"
      class="sidebar__section">
      <p class="sidebar__title">
        {{ $t('keywords') }}
      </p>
      <div class="flex flex-wrap mt-n1 mr-n1">
        <CyTag
          v-for="(keyword, id) in stack.keywords"
          :key="id"
          class="mt-1 mr-1"
          small
          variant="default">
          {{ keyword }}
        </CyTag>
      </div>
    </div>

    <template v-if="!_.isEmpty(catalogRepository)">
      <div class="sidebar__section">
        <p class="sidebar__title">
          {{ $t('CatalogRepository') }}
        </p>
        <router-link
          :to="{ name: 'catalogRepository', params: { catalogRepositoryCanonical: catalogRepository.canonical } }"
          :class="[{ 'cy-link': hasRepoURL }]">
          <span class="truncate">{{ catalogRepository.name }}</span>
        </router-link>
      </div>
      <div class="sidebar__section">
        <p class="sidebar__title">
          {{ $t('sourceCode') }}
        </p>
        <a
          v-if="hasRepoURL"
          :href="$sanitizeUrl(repoURL)"
          target="_blank"
          rel="noopener noreferrer"
          class="cy-link cy-link--external d-flex align-center">
          <v-icon
            size="16"
            color="primary"
            class="mr-1">
            {{ repoInfo.provider.icon }}
          </v-icon>
          <span class="truncate">{{ repoInfo.text }}</span>
        </a>
        <span
          v-else
          class="truncate">
          {{ catalogRepository.url }}
        </span>
      </div>
    </template>

    <div class="sidebar__section">
      <p class="sidebar__title">
        <span>Ref</span>
        <CyTooltip left>
          <template #activator="{ on }">
            <v-icon
              class="clickable ml-2"
              size="16"
              color="grey"
              v-on="on">
              help_outline
            </v-icon>
          </template>
          {{ $t('refHelp') }}
        </CyTooltip>
      </p>
      <div class="space-x-1">
        <span>{{ stackRef }}</span>
        <CyCopyBtn
          v-if="!_.isEmpty(stackRef)"
          small
          slot="append"
          :copy-hint="stackRef"
          :copy-value="stackRef"/>
      </div>
    </div>

    <div
      v-if="$showFeature.costEstimation"
      class="sidebar__section">
      <p class="sidebar__title">
        {{ $t('costEstimation') }}
      </p>
      <div>from <strong>XX</strong>$/month</div>
    </div>

    <div class="sidebar__section">
      <p class="sidebar__title">
        <span>{{ $t('Projects') }}</span>
        <span class="badge ml-2">{{ projectsUsingStack.length }}</span>
      </p>
      <div class="d-flex flex-column space-y-1">
        <router-link
          v-for="project of projectsUsingStack"
          :key="project.id"
          :to="{ name: 'project', params: { projectCanonical: project.canonical } }">
          <CyTag
            element-type="button"
            small
            variant="default">
            {{ project.name }}
          </CyTag>
        </router-link>
      </div>
    </div>

    <div
      v-if="!_.isEmpty(stack.dependencies)"
      class="sidebar__section">
      <p class="sidebar__title">
        <span>{{ $t('dependencies') }}</span>
        <span class="badge ml-2">{{ stack.dependencies.length }}</span>
        <CyTooltip left>
          <template #activator="{ on }">
            <v-icon
              class="clickable ml-2"
              size="16"
              color="grey"
              v-on="on">
              help_outline
            </v-icon>
          </template>
          {{ $t('dependenciesHelp', { stack: stack.name }) }}
        </CyTooltip>
      </p>
      <div class="d-flex flex-column space-y-1">
        <router-link
          v-for="dependency of stack.dependencies"
          :key="dependency.id"
          :to="{ name: 'stack', params: { stackRef: dependency.ref } }">
          <CyTag
            element-type="button"
            small
            variant="default">
            {{ dependency.ref }}
          </CyTag>
        </router-link>
      </div>
    </div>

    <v-card
      v-if="showHelperCard"
      flat
      class="sidebar__section stack-insights-card">
      <v-icon
        class="stack-insights-card__icon"
        size="192">
        fa-cubes
      </v-icon>
      <v-card-text class="stack-insights-card__content">
        <p class="font-weight-bold mb-0">
          {{ $t('helperCard.title') }}
        </p>
        <p class="mb-2">
          {{ $t('helperCard.subtitle') }}
        </p>
        <div class="d-flex flex-column space-y-1">
          <a
            :href="$docLinks.stacks.overview"
            rel="noopener noreferrer"
            target="_blank"
            class="cy-link cy-link--external">{{ $t('helperCard.links.concept') }}</a>
          <a
            :href="$docLinks.stacks.design"
            rel="noopener noreferrer"
            target="_blank"
            class="cy-link cy-link--external">{{ $t('helperCard.links.design') }}</a>
          <a
            :href="$docLinks.stacks.stepByStep"
            rel="noopener noreferrer"
            target="_blank"
            class="cy-link cy-link--external">{{ $t('helperCard.links.stepByStep') }}</a>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import CyCopyBtn from '@/components/copy-btn.vue'

export default {
  name: 'CyWizardServiceDetailsSidebar',
  components: {
    CyCopyBtn,
  },
  props: {
    stack: {
      type: Object,
      required: true,
    },
    catalogRepository: {
      type: Object,
      default: null,
    },
    showHelperCard: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('organization', {
      projects: (state) => state.available.projects,
    }),
    ...mapGetters('organization/cloudCostManagement', [
      'getProviderExtraInfoByCredType',
    ]),
    providers () {
      const { keywords = [] } = this.stack
      return keywords
        .filter((keyword) => keyword?.startsWith('provider:'))
        .sort()
        .map((providerId) => providerId.split(':')[1])
    },
    projectsUsingStack () {
      return _.filter(this.projects, ['service_catalog.ref', this.stackRef])
    },
    stackRef () {
      return this.stack?.ref
    },
    hasRepoURL () {
      return !_.isEmpty(this.catalogRepository?._repoURL)
    },
    repoInfo () {
      if (!this.hasRepoURL || !this.catalogRepository) return ''
      return this.catalogRepository._repoURL
    },
    repoURL () {
      return this.repoInfo?.getLink(this.stack?.directory)
    },
  },
  watch: {
    $route (to, from) {
      if (to !== from) { location.reload() }
    },
  },
  async mounted () {
    await this.FETCH_AVAILABLE({ keyPath: 'projects' })
  },
  methods: {
    ...mapActions('organization', ['FETCH_AVAILABLE']),
  },
  i18n: {
    messages: {
      en: {
        costEstimation: 'Cost estimation',
        keywords: 'Keywords',
        providers: 'Providers',
        sourceCode: 'Git repository',
        dependencies: 'Dependencies',
        dependenciesHelp: 'These stacks are required by {stack} and must be available for it to execute correctly.',
        technologies: 'Technologies',
        refHelp: 'A unique identifier that can be used to reference this stack.',
        helperCard: {
          title: 'New to stacks?',
          subtitle: 'Check out the following guides to get up and running:',
          links: {
            concept: 'The stack concept',
            design: 'Designing a stack',
            stepByStep: 'Step-by-step stack creation guide',
          },
        },
      },
      es: {
        costEstimation: 'Estimación de costos',
        keywords: 'Palabras clave',
        providers: 'Proveedores',
        sourceCode: 'Repositorio git',
        dependencies: 'Dependencias',
        dependenciesHelp: 'Estos stacks son requeridos por {stack} y deben estar disponibles para que se ejecute correctamente.',
        technologies: 'Tecnologías',
        refHelp: 'Un identificador único que se puede usar para hacer referencia a este stack.',
        helperCard: {
          title: '¿Nuevo en stacks?',
          subtitle: 'Consulta las siguientes guías para comenzar:',
          links: {
            concept: 'El concepto de stack',
            design: 'Diseñando una stack',
            stepByStep: 'Guía paso a paso para crear una stack',
          },
        },
      },
      fr: {
        costEstimation: 'Estimation du coût',
        keywords: 'Mots clés',
        providers: 'Fournisseurs',
        sourceCode: 'Dépôt git',
        dependencies: 'Dépendances',
        dependenciesHelp: `Ces stacks sont requises par {stack} et doivent être disponibles pour qu'il fonctionne correctement.`,
        technologies: 'Technologies',
        refHelp: 'Un identifiant unique qui peut être utilisé pour faire référence à ce stack.',
        helperCard: {
          title: 'Nouveau dans les stacks ?',
          subtitle: 'Consultez les guides suivants pour commencer :',
          links: {
            concept: 'Le concept de stack',
            design: `Conception d'une stack`,
            stepByStep: 'Guide de création de stack étape par étape',
          },
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.sidebar {
  display: flex;
  flex-direction: column;

  &__section + &__section {
    margin-top: $spacer-4;
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: $spacer;
    font-weight: bold;
  }

  .truncate {
    display: block;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.badge {
  padding: 0 #{$spacer};
  border-radius: $spacer-half;
  background-color: cy-get-color("grey", "light-1");
  font-size: $font-size-sm;
  font-weight: $font-weight-bolder;
}

.stack-insights-card {
  position: relative;
  overflow: hidden;
  border: solid 1px cy-get-color("secondary", "light-2");
  background-color: cy-get-color("secondary", "light-4");

  &__icon {
    position: absolute;
    top: 0;
    right: -#{$spacer-4};
    color: cy-get-color("secondary", "light-3");
  }

  &__content {
    position: relative;
  }
}
</style>
