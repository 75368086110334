import { render, staticRenderFns } from "./forms-summary.vue?vue&type=template&id=27d8fe74&scoped=true"
import script from "./forms-summary.vue?vue&type=script&lang=js"
export * from "./forms-summary.vue?vue&type=script&lang=js"
import style0 from "./forms-summary.vue?vue&type=style&index=0&id=27d8fe74&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27d8fe74",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VRow})
