<template>
  <v-switch
    :key="value"
    v-model="innerModel"
    v-bind="$attrs"
    class="cy-inputs-switch mt-0"
    color="secondary"
    v-on="$listeners"
    @change="$emit('input', Boolean($event))"/>
</template>

<script>
export default {
  name: 'CyFormsWidgetSwitch',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    innerModel: true,
  }),
  mounted () {
    this.innerModel = Boolean(this.value)
    this.$emit('input', this.innerModel)
  },
}
</script>
