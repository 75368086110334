<template>
  <v-slider
    :value="parseValue(value)"
    v-bind="$attrs"
    track-color="grey lighten-1"
    ticks="always"
    always-dirty
    :class="['cy-widget-slider', 'cy-inputs-slider-list', width]"
    :color="$attrs.disabled ? 'grey lighten-1' : 'secondary'"
    :max="items.length - 1"
    :tick-labels="labels"
    :tick-size="7"
    @change="emitTypedValue($event)"/>
</template>

<script>
export default {
  name: 'CyFormsWidgetSliderList',
  props: {
    value: {
      type: [String, Boolean, Number, Object],
      default: '',
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    width: {
      type: String,
      default: 'is-x-large',
    },
    type: {
      type: String,
      default: 'string',
    },
    hasValueMapping: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    labels () {
      return _.map(this.items, (item) => String(this.hasValueMapping ? item.label : item))
    },
  },
  watch: {
    items: {
      handler () {
        if (_.isEmpty(this.value)) this.emitTypedValue(0)
      },
      immediate: true,
    },
  },
  methods: {
    parseValue (value) {
      if (this.hasValueMapping) {
        const label = _.isObject(value) ? value.label ?? value : value
        return this.items.map((item) => item.label).indexOf(label)
      }
      return this.items.indexOf(value)
    },
    emitTypedValue (value) {
      if (_.isEmpty(this.items)) return
      let constructor = null

      switch (this.type) {
        case 'map':
        case 'string':
          constructor = String
          break
        case 'integer':
          constructor = Number
          break
        case 'boolean':
          constructor = Boolean
          break
      }

      const item = this.hasValueMapping
        ? this.items[Number(value)].label
        : this.items[Number(value)]

      this.$emit('input', constructor(item))
    },
  },
}
</script>

<style lang="scss" scoped>
$app-bg: cy-get-color("background");
$ticks-color: cy-get-color("secondary");
$ticks-color-disabled: cy-get-color("grey", "dark-1");
$width-x-large: 440px;
$width-xx-large: 860px;
$size-variants: (
  "is-x-large": $width-x-large,
  "is-xx-large": $width-xx-large
);

.cy-inputs-slider-list {
  width: $width-x-large;
  padding-right: 6px;
  padding-left: 6px;

  ::v-deep .v-slider__tick {
    display: flex;
    left: 100%;
    justify-content: center;
    width: 1rem !important;
    height: 1rem !important;
    margin-top: -4px;
    margin-left: -4px;
    border: 2px solid #{$ticks-color} !important;
    border-radius: 50%;
    background-color: $app-bg;

    &:first-child {
      justify-content: flex-start;
    }

    &:last-child {
      justify-content: flex-end;
    }

    &-label {
      top: 18px;
      transform: unset !important;
      color: cy-get-color("grey", "dark-2");
    }
  }

  @each $name, $width in $size-variants {
    &.#{$name} {
      width: $width;

      ::v-deep {
        .v-select__selections {
          width: $width - 50px;

          .v-select__selection--comma {
            width: $width;
          }
        }
      }
    }
  }

  &.v-input--is-disabled {
    ::v-deep .v-slider__tick {
      border: 2px solid #{$ticks-color-disabled} !important;
    }
  }

  ::v-deep input {
    z-index: 1;
    width: 110%;
    height: 62px;
    max-height: 62px;
  }
}
</style>
