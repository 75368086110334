var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"resource-input-field",attrs:{"data-cy":"resource-input-field"}},[_c('v-select',{staticClass:"cy-widget-input inventory-resource-input",attrs:{"placeholder":_vm.value ? '' : _vm.$t('selectResource'),"items":_vm.selectedResources,"value":_vm.multiple ? _vm._.map(_vm.selectedResources, 'id') : _vm._.get(_vm.selectedResources, '[0].id', null),"item-value":"id","item-text":"name","append-icon":"chevron_right","error-messages":_vm.getErrors,"required":_vm.required,"multiple":_vm.multiple,"readonly":""},on:{"blur":function($event){return _vm.$v.selectedResources.$touch()},"focus":_vm.openSidePanel},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(index === 0)?_c('div',[(_vm.multiple)?_c('span',[(_vm.value)?_c('span',[_vm._v(" "+_vm._s(_vm.$tc('nSelected', _vm.value.length, { n: _vm.value.length }))+" ")]):_vm._e()]):_c('div',{staticClass:"d-flex align-center"},[_c('div',[_c('CyInventoryResourceIcon',{staticClass:"mr-2",attrs:{"image-src":_vm.getImage(item),"is-custom":_vm.isCustomResource(item),"data-cy":"item-icon","size":"32"}})],1),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"selected-resource__name"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"selected-resource__id"},[_vm._v(" "+_vm._s(_vm._.get(item, 'attributes.id', _vm._.get(item, 'custom_attributes.id', '')))+" ")])])])]):_vm._e()]}},{key:"prepend-inner",fn:function(){return [(!_vm.multiple && _vm.value && _vm._.isEmpty(_vm.selectedResources))?_c('div',[_c('div',{staticClass:"selected-resource__attribute"},[_vm._v(" "+_vm._s(_vm.value)+" ")])]):(_vm.multiple && !_vm._.isEmpty(_vm.value) && _vm._.isEmpty(_vm.selectedResources))?_c('div',[_c('div',{staticClass:"selected-resource__attribute"},[_vm._v(" "+_vm._s(_vm.$tc('nSelected', _vm.value.length, { n: _vm.value.length }))+" ")])]):_vm._e()]},proxy:true}])})],1),(_vm.sidePanelOpen)?_c('portal',{attrs:{"to":"side-panel"}},[_c('div',{staticClass:"side-panel-backdrop"}),_c('v-slide-x-reverse-transition',[_c('v-card',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
          handler: _vm.closeSidebar,
          include: _vm.getClickOutsideExceptions,
        }),expression:"{\n          handler: closeSidebar,\n          include: getClickOutsideExceptions,\n        }"}],staticClass:"side-panel",attrs:{"data-cy":"inventory-resource-side-panel"}},[_c('div',{staticClass:"side-panel__header d-flex pa-6 pb-4"},[_c('div',{staticClass:"flex-grow-1"},[_c('div',{staticClass:"side-panel__title"},[_vm._v(" "+_vm._s(_vm.name)+" ")]),(_vm.description)?_c('div',{staticClass:"side-panel__description"},[_vm._v(" "+_vm._s(_vm.description)+" ")]):_vm._e()]),_c('div',{staticClass:"flex-shrink-0"},[_c('v-icon',{attrs:{"data-cy":"panel-close-button"},on:{"click":_vm.closeSidebar}},[_vm._v(" close ")])],1)]),_c('v-card-text',{staticClass:"px-6 flex-grow-1 cy-scrollbars"},[_c('CyDataTableYdApi',{ref:"resourceTable",staticClass:"resource-table",attrs:{"fetch-available":{ keyPath: 'inventoryResources' },"working-route":_vm.workingRoute,"headers":_vm.$static.headers,"searchable-fields":_vm.$static.searchableFields,"custom-search-placeholder":_vm.$t('inventory.filterByKeyword'),"immediate":false,"prefilters":_vm.prefilterQueryObject,"parse-items-array":_vm.excludeResourcesWithoutRequestedAttribute,"data-cy":"resources-table","elevation":"0","key-field":"id","bulk":_vm.multiple,"single-select":!_vm.multiple},scopedSlots:_vm._u([{key:"table-cmp-body-row",fn:function(ref){
        var item = ref.props.item;
return [(_vm.multiple)?_c('td'):_vm._e(),_c('td',{key:"name"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"line-height-xs"},[_c('CyInventoryResourceIcon',{staticClass:"mr-2",attrs:{"image-src":_vm.getImage(item),"is-custom":_vm.isCustomResource(item),"data-cy":"item-icon","size":"32"}})],1),_c('div',[_c('div',{staticClass:"resource-row__name"},[_c('CyTooltip',{attrs:{"open-delay":"250","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.name)+" ")])],1),_c('div',{staticClass:"resource-row__id"},[_c('CyTooltip',{attrs:{"open-delay":"250","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm._.get(item, 'attributes.id', null))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm._.get(item, 'attributes.id', null))+" ")])],1)])])]),_c('td',{key:"type"},[_c('div',{staticClass:"resource-row__type"},[_c('CyTooltip',{attrs:{"open-delay":"250","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(item.type)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.type)+" ")])],1)]),_c('td',{key:"project"},[_c('div',{staticClass:"resource-row__project"},[_c('CyTooltip',{attrs:{"open-delay":"250","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(item.project_canonical)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.project_canonical)+" ")])],1)]),_c('td',{key:"environment"},[_c('div',{staticClass:"resource-row__environment"},[_c('CyTooltip',{attrs:{"open-delay":"250","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(item.environment_canonical)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.environment_canonical)+" ")])],1)]),_c('td',{key:"goToDetail"},[_c('router-link',{attrs:{"data-cy":"resource-detail-link","to":{ name: 'inventory', query: { resourceId: item.id, resourceTab: 'details' } },"target":"_blank"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" open_in_new ")])],1)],1)]}},{key:"table-cmp-no-data",fn:function(){return [_c('div',{staticClass:"d-flex flex-column align-center justify-center no-resources"},[_c('v-icon',{staticClass:"no-resources__icon",attrs:{"size":"32"}},[_vm._v(" dns ")]),_c('div',{staticClass:"no-resources__title",domProps:{"textContent":_vm._s(_vm.$t('noResources.title'))}}),_c('div',{staticClass:"no-resources__subtitle",domProps:{"textContent":_vm._s(_vm.$t('noResources.subtitle'))}})],1)]},proxy:true}],null,false,708431337),model:{value:(_vm.selectedResources),callback:function ($$v) {_vm.selectedResources=$$v},expression:"selectedResources"}},[_c('template',{slot:"table-cmp-header-filters"},[_c('CyFilterTree',{attrs:{"filter-tree":_vm.filters,"value":_vm.selectedFilters},on:{"input":_vm.setFilterValues}})],1),_c('template',{slot:"cy-table-cmp-header-append"},[_c('div',{staticClass:"pb-4"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$sanitizeHtml(_vm.itemCountText))}}),(!_vm._.isEmpty(_vm.prefilterQueryObject))?_c('CyMenu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-1",attrs:{"size":"16"}},on),[_vm._v(" info_outline ")])]}}],null,false,2266119430)},[_c('div',{staticClass:"prefilters-menu pa-4"},[_c('div',{domProps:{"textContent":_vm._s(_vm.$t('prefilterMenu.text1'))}}),_c('div',{staticClass:"mb-4",domProps:{"textContent":_vm._s(_vm.$t('prefilterMenu.text2'))}}),_vm._l((_vm.standardPrefilterTags),function(ref){
        var tagKey = ref[0];
        var tagValue = ref[1];
return _c('div',{key:tagKey,staticClass:"mb-2"},[_c('CyTag',{attrs:{"variant":"default"}},[_c('b',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm._.capitalize(tagKey))+": ")]),_c('span',[_vm._v(" "+_vm._s(tagValue)+" ")])])],1)}),_vm._l((_vm.attributePrefilterTags),function(ref){
        var tagKey = ref[0];
        var tagValue = ref[1];
return _c('div',{key:tagKey,staticClass:"mb-2"},[_c('CyTag',{attrs:{"variant":"warning"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"20","color":"warning darken-3"}},[_vm._v(" data_object ")]),_c('b',{staticClass:"mr-2 warning--text text--darken-3"},[_vm._v(" "+_vm._s(tagKey)+": ")]),_c('span',{staticClass:"warning--text text--darken-3"},[_vm._v(" "+_vm._s(tagValue)+" ")])],1)],1)})],2)]):_vm._e()],1)])],2)],1),_c('v-card-actions',{staticClass:"d-flex justify-space-between px-6 py-4"},[(!_vm._.isEmpty(_vm.selectedResources))?_c('CyButton',{attrs:{"variant":"tertiary","theme":"grey"},on:{"click":_vm.clearSelection}},[_vm._v(" "+_vm._s(_vm.$t('clearSelection'))+" ")]):_vm._e(),_c('div',[(!_vm._.isEmpty(_vm.selectedResources))?_c('CyButton',{attrs:{"icon":"check"},on:{"click":_vm.confirmSelection}},[_vm._v(" "+_vm._s(_vm.$t('confirmSelection'))+" ")]):_vm._e()],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }