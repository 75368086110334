<template>
  <div class="cy-inputs-slider-range__container">
    <v-slider
      :value="value"
      v-bind="$attrs"
      thumb-label="always"
      always-dirty
      class="cy-widget-slider cy-inputs-slider-range__slider"
      :min="min"
      :max="max"
      :color="$attrs.disabled ? 'grey lighten-1' : 'secondary'"
      v-on="$listeners">
      <template slot="prepend">
        <label class="v-label theme--light font-weight-bold">
          {{ min }}
        </label>
      </template>
      <template slot="append">
        <label class="v-label theme--light font-weight-bold">
          {{ max }}
        </label>
      </template>
      <template slot="thumb-label">
        <span class="theme--light font-weight-black range-value">
          {{ value }}
        </span>
      </template>
    </v-slider>

    <v-text-field
      v-bind="$attrs"
      :key="helperInputKey"
      :value="value"
      single-line
      class="cy-widget-input cy-inputs-number cy-inputs-slider-range__helper-input"
      :min="min"
      :max="max"
      v-on="$listeners"
      @change="emitTypedValue($event)"/>
  </div>
</template>

<script>
export default {
  name: 'CyFormsWidgetSliderRange',
  props: {
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 10,
    },
    value: {
      type: [Number, String],
      default: 0,
      required: true,
    },
  },
  data: () => ({
    helperInputKey: Date.now(),
  }),
  mounted () {
    const { min, max, value } = this
    const defaultValue = (_.$isEmpty(value) || value < min || value > max) ? min : value
    this.$emit('input', Number(defaultValue))
  },
  methods: {
    emitTypedValue (value) {
      const { min, max } = this
      let emitValue = value
      if (value < min) emitValue = min
      if (value > max) emitValue = max

      // We update this value that is used as key,
      // to force trigger a refresh on v-text-field
      this.helperInputKey = Date.now()

      this.$emit('input', Number(emitValue))
    },
  },
}
</script>

<style lang="scss" scoped>
$width-x-large: 360px;

.cy-inputs-slider-range {
  &__container {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__helper-input {
    max-width: 70px;

    ::v-deep {
      .v-input__control {
        input {
          padding-right: 1rem;
          padding-left: 1rem;
          text-align: center !important;
        }
      }
    }
  }

  &__slider {
    width: $width-x-large;

    ::v-deep {
      .range-value {
        font-size: 1rem;
      }

      .v-slider__thumb-label {
        display: none;
      }
    }
  }
}
</style>
