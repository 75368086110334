<template>
  <div
    v-if="_.isEmpty(quotas) && !fetchingQuotas"
    class="requirement-panel">
    <CyAlert
      v-if="errorMessage"
      theme="error"
      :content="errorMessage"/>
  </div>
  <div
    v-else-if="!loading && !debouncing[env]"
    class="requirement-panel">
    <CyAlert
      v-if="errorMessage"
      theme="error"
      :content="errorMessage"/>
    <v-select
      v-if="isFormValid && !envRequirementErrors"
      v-model="resourcePoolCanonical"
      data-cy="resource-pool-input"
      class="required-field mb-6"
      item-text="resource_pool.name"
      item-value="resource_pool.canonical"
      persistent-hint
      :label="$t('ResourcePool')"
      :hint="$t('resourcePoolHint')"
      :disabled="!isCreationMode || quotas.length < 2"
      :items="quotas"/>
    <div
      v-if="isFormValid && !envRequirementErrors"
      class="requirements">
      <div
        v-for="type in ['memory', 'storage', 'cpu']"
        :key="type"
        :class="[`requirement ${type}-requirement d-flex mb-4`, { 'requirement--insufficient': !isTypeSufficient(type) }]">
        <v-icon
          class="mr-4"
          :color="isTypeSufficient(type) ? 'success': 'error'">
          {{ isTypeSufficient(type) ? 'check' : 'cancel' }}
        </v-icon>
        <div class="requirement__text">
          <h4 v-text="$t(`quotas.${type}`)"/>
          <div v-text="getRequirementText(type)"/>
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="requirement-panel--loading d-flex justify-center pt-8">
    <v-progress-circular
      color="secondary"
      indeterminate/>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import { getFormattedUnits } from '@/utils/helpers/quotas'

export default {
  name: 'CyQuotasRequirementPanel',
  props: {
    stackFormsInput: {
      type: Object,
      required: true,
    },
    isFormValid: {
      type: Boolean,
      required: true,
    },
    isCreationMode: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    debounce: null,
  }),
  computed: {
    ...mapState('organization', {
      fetchingQuotas: (state) => state.fetchInProgress.quotas,
      quotas: (state) => state.available.quotas,
    }),
    ...mapState('organization/project', {
      project: (state) => state.detail,
    }),
    ...mapState('organization/quota', {
      debouncing: (state) => state.debouncing,
      envsResourcePool: (state) => state.envsResourcePool,
      fetchingRequirements: (state) => state.fetchingRequirements,
      originalRequirements: (state) => state.originalRequirements,
      requirementErrors: (state) => state.requirementErrors,
    }),
    ...mapGetters('organization/quota', [
      'envRequirement',
      'isEnvValid',
      'getAvailableByType',
    ]),
    resourcePoolCanonical: {
      get () {
        return this.envsResourcePool[this.env]
      },
      async set (value) {
        const { env } = this
        this.SET_ENV_RESOURCE_POOL({ env, canonical: value })
        if (this.isEnvValid(this.env) && this.isFormValid) this.VALIDATE_ENV({ env })
        else this.INVALIDATE_ENV({ env })
      },
    },
    env () {
      return _.get(this.stackFormsInput, 'inputs[0].environment_canonical', null)
    },
    envRequirementErrors () {
      return this.requirementErrors[this.env]
    },
    useCase () {
      return _.get(this.stackFormsInput, 'inputs[0].use_case', null)
    },
    errorMessage () {
      const exceedingQuotaMessage = this.$t(`errors.exceedingQuota.${this.isCreationMode ? 'creating' : 'editing'}`)
      if (_.isEmpty(this.quotas) && !this.fetchingQuotas) return this.$t('errors.noQuotas')
      if (this.envRequirementErrors) return this.$t('errors.unexpectedError')
      if (!this.isFormValid) return this.$t('errors.invalidForm')
      if (!this.isEnvValid(this.env)) return exceedingQuotaMessage
      return null
    },
    selectedQuota () {
      return _.find(this.quotas, ['resource_pool.canonical', this.resourcePoolCanonical]) || null
    },
    loading () {
      return this.fetchingQuotas || this.fetchingRequirements[this.env]
    },
    teamCanonical () {
      return _.get(this.project, 'team_canonical') || _.get(this.project, 'team.canonical')
    },
  },
  watch: {
    stackFormsInput: {
      handler (newValue, oldValue) {
        if (
          _.isEqual(_.get(newValue, 'inputs[0].vars'), _.get(oldValue, 'inputs[0].vars')) &&
          (_.get(oldValue, 'inputs[0].resource_pool_canonical') ||
          !_.get(newValue, 'inputs[0].resource_pool_canonical'))) return
        this.SET_DEBOUNCING_ENV_CHANGE({ env: this.env, value: true })
        clearTimeout(this.debounce)
        this.debounce = setTimeout(() => {
          this.fetchRequirementIfValid()
          this.SET_DEBOUNCING_ENV_CHANGE({ env: this.env, value: false })
        }, 1000)
      },
      deep: true,
    },
  },
  async mounted () {
    this.fetchQuotas()
  },
  destroyed () {
    this.RESET_ENV_DATA(this.env)
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('organization/quota', [
      'GET_ENV_REQUIREMENT',
    ]),
    ...mapMutations('organization/quota', [
      'INVALIDATE_ENV',
      'VALIDATE_ENV',
      'SET_ENV_RESOURCE_POOL',
      'SET_ORIGINAL_ENV_REQUIREMENT',
      'RESET_ENV_DATA',
      'SET_DEBOUNCING_ENV_CHANGE',
    ]),
    async fetchQuotas () {
      await this.FETCH_AVAILABLE({
        keyPath: 'quotas',
        extraParams: [{
          'team_canonical[in]': this.teamCanonical,
        }],
      })
      const resourcePoolCanonical = _.get(this.stackFormsInput, 'inputs[0].resource_pool_canonical')
      this.resourcePoolCanonical = resourcePoolCanonical || _.head(this.quotas)?.resource_pool?.canonical || null
    },
    async fetchRequirementIfValid () {
      const { stackFormsInput, isFormValid, env, useCase } = this
      this.INVALIDATE_ENV({ env })
      if (!isFormValid) return
      await this.GET_ENV_REQUIREMENT({ projectCanonical: this.project.canonical, stackFormsInput, env, useCase })
      if (!this.isCreationMode && _.isEmpty(this.originalRequirements?.[this.env])) {
        this.SET_ORIGINAL_ENV_REQUIREMENT({ env: this.env, requirement: this.envRequirement(this.env) })
      }
      if (this.isEnvValid(this.env)) this.VALIDATE_ENV({ env })
    },
    getRequiredByType (type) {
      const requirement = this.envRequirement(this.env)
      return requirement?.[type]
    },
    getRequirementText (type) {
      const required = this.getRequiredByType(type)
      const requiredText = getFormattedUnits(required, type)

      const available = this.getAvailableByType(this.selectedQuota, type, this.env)
      const availableText = getFormattedUnits(available, type)

      return `${requiredText} ${this.$t('required').toLowerCase()} • ${availableText} ${this.$t('available').toLowerCase()}`
    },
    isTypeSufficient (type) {
      return this.getAvailableByType(this.selectedQuota, type, this.env) >= this.getRequiredByType(type)
    },
  },
  i18n: {
    messages: {
      en: {
        resourcePoolHint: 'The resource pool to consume resources from.',
        required: 'Required',
        available: 'Available',
        errors: {
          exceedingQuota: {
            creating: '<b>Exceeding team quota. </b>Either select another resource pool or contact an administrator to request a quota increase.',
            editing: '<b>Exceeding team quota. </b>To apply these changes, you will need to contact an administrator to request a quota increase.',
          },
          invalidForm: '<b>Invalid form content. </b>Please fill out the form correctly in order to view resource consumption.',
          unexpectedError: 'An unexpected error occured when fetching quota requirements',
          noQuotas: '<b>No quota instance</b><br>Your team does not have any quota instance associated to it, but this stack requires one. <br>Please contact an administrator to get your quotas set up.',
        },
      },
      es: {
        resourcePoolHint: 'El grupo de recursos del que consumir recursos.',
        required: 'Requeridos',
        available: 'Disponibles',
        errors: {
          exceedingQuota: {
            creating: '<b>Excediendo la quota del equipo. </b>Seleccione otro grupo de recursos o comuníquese con un administrador para solicitar un aumento de quota.',
            editing: '<b>Excediendo la quota del equipo. </b>Para aplicar estos cambios, deberá ponerse en contacto con un administrador para solicitar un aumento de la quota.',
          },
          invalidForm: '<b>Contenido del formulario no válido. </b>Por favor complete el formulario correctamente para ver el consumo de recursos.',
          unexpectedError: 'Se produjo un error inesperado al obtener los requisitos de quota',
          noQuotas: '<b>Sin instancia de quota</b><br>Su equipo no tiene ninguna instancia de quota asociada, pero este stack requiere una.<br>Comuníquese con un administrador para configurar sus quotas.',
        },
      },
      fr: {
        resourcePoolHint: 'Le pool de ressources à partir duquel consommer des ressources.',
        required: 'Requis',
        available: 'Disponibles',
        errors: {
          exceedingQuota: {
            creating: `<b>Dépassement du quota de l'équipe. </b>Sélectionnez un autre pool de ressources ou contactez un administrateur pour demander une augmentation de quota.`,
            editing: `<b>Dépassement du quota de l'équipe. </b>Pour appliquer ces changements, vous devrez contacter un administrateur pour demander une augmentation de quota.`,
          },
          invalidForm: '<b>Contenu du formulaire invalide. </b>Veuillez remplir le formulaire correctement afin de visualiser la consomation en ressources.',
          unexpectedError: `Une erreur innatendue s'est produite lors du calcul des quotas`,
          noQuotas: `<b>Aucune instance de quotas</b><br>Votre équipe n'a aucune instance de quota qui lui est associée, mais cette stack en requiert une. <br>Contactez un administrateur pour configurer vos quotas.`,
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.requirement {
  &__text {
    color: cy-get-color("primary");
  }

  &--insufficient {
    .requirement__text :not(h4) {
      color: cy-get-color("error");
    }
  }
}
</style>
