<template>
  <div
    :class="['resource-icon', { 'resource-icon--custom': isCustom }]"
    :style="`--size: ${size}px`">
    <v-icon
      v-if="isCustom"
      data-cy="icon">
      dns
    </v-icon>
    <v-img
      v-else
      :src="imageSrc"
      :max-width="size"
      :max-height="size"
      :width="size"
      :height="size"
      contain
      data-cy="image"/>
  </div>
</template>

<script>
export default {
  name: 'CyInventoryResourceIcon',
  props: {
    imageSrc: {
      type: String,
      default: '',
    },
    isCustom: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '24',
    },
  },
}
</script>

<style lang="scss" scoped>
.resource-icon {
  width: var(--size);
  height: var(--size);
  overflow: hidden;
  border-radius: 4px;
  background-color: cy-get-color("white");
  box-shadow: 0 0 0 1px inset cy-get-color("white");

  &--custom {
    padding: calc(var(--size) * 1 / 6);
    background-color: cy-get-color("primary", "light-3");

    ::v-deep .v-icon {
      color: cy-get-color("primary", "light-5");
      font-size: calc(var(--size) * 4 / 6);
    }
  }
}
</style>
