<template>
  <div>
    <CyCodeEditor
      ref="codeEditor"
      :read-only="$attrs.disabled || $attrs.readonly"
      :value="parsedValue"
      :code-lang="editorCodelang"
      show-gutter
      @input="emitTypedValue($event)"
      @blur="onEditorBlur"/>
    <div
      v-if="getHint || getErrors"
      class="v-text-field__details pt-2">
      <div :class="['v-messages','theme--light', { 'error--text': getErrors.length }]">
        <div class="v-messages__wrapper">
          <div
            v-if="getErrors.length"
            class="v-messages__message">
            <span>
              {{ getErrors[0] }}
            </span>
          </div>
          <div v-else>
            {{ getHint }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CyCodeEditor from '@/components/code-editor.vue'
import { maxArrayLength, minArrayLength, mustExistInArray, mustExistKeysInMap, generateRuleName } from '@/utils/helpers/validators'
import * as yaml from 'js-yaml'
import { requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'CyFormsWidgetTextarea',
  components: {
    CyCodeEditor,
  },
  props: {
    technology: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'string',
    },
    value: {
      type: [String, Boolean, Number, Array, Object],
      default: '',
      required: true,
    },
    formsValidations: {
      type: Array,
      default () {
        return []
      },
    },
  },
  validations () {
    if (this.formsValidations) {
      return {
        value: {
          required: requiredIf(function () {
            return this.required
          }),
          ...(this.formsValidations).reduce((validators, validation) => {
            if (validation.array) this.addArrayValidators(validation, validators)
            if (validation.map) this.addMapValidators(validation, validators)
            return validators
          }, {}),
        },
      }
    }
    return {
      value: {
        required: requiredIf(function () {
          return this.required
        }),
      },
    }
  },
  data: () => ({
    invalidYaml: false,
  }),
  computed: {
    editorCodelang () {
      if (this.type === 'map' || this.type === 'array') return 'yaml'
      if (this.type === 'raw') {
        try {
          if (_.isObject(JSON.parse(this.value))) return 'json'
        } catch (error) {
          return 'yaml'
        }
      }
      return 'text'
    },
    getErrors () {
      const errors = []
      const { isOfType, type, required, technologyFormat, formsValidations } = this
      const {
        $dirty,
        $error,
        required: $vRequired,
        ...validationChecks
      } = this.$v.value

      if ($dirty && !$vRequired && required) errors.push(this.$t('forms.fieldRequired'))
      if (this.invalidYaml) errors.push(this.$t('invalidYamlError'))
      else if (!isOfType) {
        const requestedType = type === 'raw' ? `${type}/${technologyFormat}` : type

        errors.push(`${this.$t('validationTypeError')} (${requestedType})`)
      }

      if ($dirty && $error && formsValidations) {
        this.formsValidations.forEach((validation) => {
          this.handleArrayValidation(validation, errors, validationChecks)
          this.handleMapValidation(validation, errors, validationChecks)
        })
      }

      return errors
    },
    getHint () {
      const { type, technologyFormat } = this

      if (type === 'string' || type === 'raw') return
      return this.$t(`hint.${type}`, { technologyFormat })
    },
    isOfType () {
      const { value, type } = this

      // don't trigger type validation if empty value, let the 'required' do its job
      if (_.$isEmpty(value)) return true

      switch (type) {
        case 'string':
        case 'raw':
          return _.isString(value)
        case 'boolean':
          return _.isBoolean(value)
        case 'integer':
          return _.isInteger(value)
        case 'array':
          // on load, the value is an array, but when editing, it's a yaml array
          if (_.isArray(value)) return true
          return _.isArray(yaml.load(value))
        case 'map':
          // on load, the value is an object, but when editing, it's a yaml map
          if (_.isPlainObject(value)) return true
          return _.isPlainObject(yaml.load(value))
        default:
          return false
      }
    },
    technologyFormat () {
      return this.technology === 'terraform' ? 'HCL' : 'YAML'
    },
    parsedValue () {
      if (this.type === 'map' || this.type === 'array') {
        try {
          if (this.type === 'array' && this.value.length === 0) return ''
          if (!_.isEmpty(this.value)) return yaml.dump(this.value)
          else return ''
        } catch (error) {
          return this.value
        }
      }
      return this.value
    },
  },
  mounted () {
    if (this.type === 'raw') this.emitTypedValue(this.value)
  },
  methods: {
    generateRuleName,
    emitTypedValue (value) {
      let emitValue = ''
      // Because of a trick on code-editor that is returning a space when empty, we had to sanitize this to trigger validation
      if (value === ' ') value = ''
      // Force the validation to trigger when content is changed
      this.$v.value.$touch()

      switch (this.type) {
        case 'string':
        case 'raw':
          emitValue = String(value || '')
          break
        case 'boolean':
          if (value === 'true') emitValue = true
          else if (value === 'false') emitValue = false
          else emitValue = value
          break
        case 'integer':
          if (_.isInteger(Number.parseInt(value))) emitValue = Number(value)
          else emitValue = value
          break
        case 'array':
        case 'map':
          emitValue = this.parseYaml(value)
          return
      }
      this.$emit('input', emitValue)
    },
    parseYaml (value) {
      this.invalidYaml = false
      if (_.$isEmpty(value)) return ''

      try {
        const parsedYaml = yaml.load(value)
        // Only objects or array are considered valid yaml
        if (!_.isObject(parsedYaml) && !_.isArray(parsedYaml)) {
          throw new Error('Invalid yaml')
        }
      } catch (error) {
        this.invalidYaml = true
        return
      }

      return value
    },
    onEditorBlur () {
      // Check if the content is invalid or empty
      if (this.invalidYaml) return
      if (_.$isEmpty(this.$refs.codeEditor.editor.getValue())) {
        if (this.type === 'array') this.$emit('input', [])
        else if (this.type === 'map') this.$emit('input', {})
        else this.$emit('input', '')
        return
      }
      // If it's a map or an array, parse the yaml and emit the value
      if ((this.type === 'map' || this.type === 'array')) {
        const parsedYaml = yaml.load(this.$refs.codeEditor.editor.getValue())
        this.$emit('input', parsedYaml)
        return
      }
      // For raw text, just emit the value
      this.$emit('input', this.$refs.codeEditor.editor.getValue())
    },
    addArrayValidators (validation, validators) {
      const {
        min_elems: minElements,
        max_elems: maxElements,
        must_exist_elems: mustExistElements,
      } = validation.array

      if (!_.isUndefined(minElements)) {
        const ruleName = this.generateRuleName('minArrayLength', minElements)
        validators[ruleName] = minArrayLength(minElements)
      }
      if (!_.isUndefined(maxElements)) {
        const ruleName = this.generateRuleName('maxArrayLength', maxElements)
        validators[ruleName] = maxArrayLength(maxElements)
      }
      if (!_.isUndefined(mustExistElements)) {
        const ruleName = this.generateRuleName('mustExistInArray', mustExistElements)
        validators[ruleName] = mustExistInArray(mustExistElements)
      }
    },
    addMapValidators (validation, validators) {
      const { must_exist_keys: mustExistKeys } = validation.map

      if (!_.isUndefined(mustExistKeys)) {
        const ruleName = this.generateRuleName('mustExistKeysInMap', mustExistKeys)
        validators[ruleName] = mustExistKeysInMap(mustExistKeys)
      }
    },
    handleArrayValidation (validation, errors, validationChecks) {
      if (!validation.array) return

      const { min_elems: minElements, max_elems: maxElements, must_exist_elems: mustExistElements } = validation.array

      if (!_.isUndefined(minElements)) {
        const minElemsRuleName = this.generateRuleName('minArrayLength', minElements)

        if (!validationChecks[minElemsRuleName]) {
          errors.push(validation.error_message || this.$tc('forms.arrayMinLength', minElements, { number: minElements }))
        }
      }

      if (!_.isUndefined(maxElements)) {
        const maxElemsRuleName = this.generateRuleName('maxArrayLength', maxElements)

        if (!validationChecks[maxElemsRuleName]) {
          errors.push(validation.error_message || this.$tc('forms.arrayMaxLength', maxElements, { number: maxElements }))
        }
      }

      if (!_.isUndefined(mustExistElements)) {
        const ruleName = this.generateRuleName('mustExistInArray', mustExistElements)

        if (!validationChecks[ruleName]) {
          const defaultErrorMessage = this.$t('forms.mustExistInArray', { elements: _.castArray(mustExistElements).join(', ') })
          errors.push(validation.error_message || defaultErrorMessage)
        }
      }
    },
    handleMapValidation (validation, errors, validationChecks) {
      if (!validation.map) return

      const { must_exist_keys: mustExistKeys } = validation.map
      const ruleName = this.generateRuleName('mustExistKeysInMap', mustExistKeys)

      if (!validationChecks[ruleName]) {
        const defaultErrorMessage = this.$t('forms.mustExistKeysInMap', { keys: _.castArray(mustExistKeys).join(', ') })
        errors.push(validation.error_message || defaultErrorMessage)
      }
    },
  },
  i18n: {
    messages: {
      en: {
        invalidYamlError: 'Invalid YAML: please check and fix your YAML input',
        validationTypeError: 'Value is not of the requested type',
        hint: {
          boolean: `Content must be 'true' or 'false'`,
          integer: 'Content must be a valid number',
          array: 'Content must be a list of values in a YAML format',
          map: 'Content must be valid YAML',
          raw: 'Content must be in a valid {technologyFormat} format',
        },
      },
      es: {
        invalidYamlError: 'YAML no válido: verifique y corrija su entrada YAML',
        validationTypeError: 'El valor no es del tipo solicitado',
        hint: {
          boolean: `El contenido debe ser 'true' o 'false'`,
          integer: 'El contenido debe ser un número válido',
          array: 'El contenido debe ser una lista de valores separados por una coma',
          map: 'El contenido debe ser un YAML valido',
          raw: 'El contenido debe estar en un formato {technologyFormat} válido',
        },
      },
      fr: {
        invalidYamlError: 'YAML invalide : veuillez vérifier et corriger votre entrée YAML',
        validationTypeError: `La valeur n'est pas du type demandé`,
        hint: {
          boolean: `Le contenu doit être 'true' ou 'false'`,
          integer: 'Le contenu doit être un nombre valide',
          array: 'Le contenu doit être une liste de valeurs séparées par une virgule',
          map: 'Le contenu doit être un YAML valide',
          raw: 'Le contenu doit être dans un format {technologyFormat} valide',
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
$app-bg: cy-get-color("background");

.code-editor {
  width: auto;
  min-width: 439px;
  height: 280px;
  overflow: auto;
  resize: both;
}
</style>
