<template>
  <v-avatar
    :key="`${stack.ref}-${stack.image}`"
    :size="size"
    :class="['cy-stack-avatar handle-img-hover', { circular }]"
    :style="`--stack-initial: '${_.get(stack.name, '[0]')}';`"
    :rounded="circular ? 'circle': undefined"
    :tile="!circular">
    <img
      loading="lazy"
      :src="stack.image || 'invalid.png'"
      :alt="stack.name"
      @load="handleChange"
      @error="replaceImageOnError">
    <div
      v-if="canUpdateStack && updateStackImage"
      class="cy-stack-avatar__update clickable"
      @click="$emit('click', $event)">
      <v-icon
        color="white"
        size="20">
        edit
      </v-icon>
      <span class="cy-stack-avatar__update-text">
        {{ $t("changeImage") }}
      </span>
    </div>
  </v-avatar>
</template>

<script>
export const defaultServiceImage = '/static/images/stack_default.png'
export const replacementCssClass = 'replacement-stack-image'

export default {
  name: 'CyStackAvatar',
  props: {
    stack: {
      type: Object,
      required: true,
    },
    updateStackImage: {
      type: Boolean,
      default: false,
    },
    canUpdateStack: {
      type: Boolean,
      default: false,
    },
    circular: {
      type: Boolean,
      default: false,
    },
    size: {
      type: [String, Number],
      default: '48',
    },
  },
  methods: {
    replaceImageOnError ({ target }) {
      target.src = defaultServiceImage
      target.parentNode.classList.add(replacementCssClass)
    },
    handleChange ({ target }) {
      const isImageValid = ![...target.parentNode.classList].includes(replacementCssClass)
      isImageValid ? this.$emit('success') : this.$emit('error')
      this.$forceUpdate()
    },
  },
  i18n: {
    messages: {
      en: {
        changeImage: 'Change stack image',
      },
      es: {
        changeImage: 'Cambiar imagen del stack',
      },
      fr: {
        changeImage: `Changer l'image del stack`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.cy-stack-avatar {
  position: relative;
  z-index: 1;
  flex-shrink: 0;

  img {
    height: initial;
    max-height: inherit;
  }

  &.replacement-stack-image {
    position: relative;

    &::after {
      content: var(--stack-initial);
      display: inline-block;
      position: absolute;
      z-index: 20;
      color: cy-get-color("primary");
      font-size: $font-size-h3;
      font-weight: $font-weight-bolder;
      text-shadow:
        -3px -3px 0 white,
        3px -3px 0 white,
        -3px 3px 0 white,
        3px 3px 0 white;
      text-transform: uppercase;
    }
  }

  &__update {
    display: flex;
    visibility: hidden !important;
    position: absolute;
    z-index: 30;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-bottom: 13px;
    opacity: 0.8;
    background-color: cy-get-color("primary", "light-2");
    color: white;

    &-text {
      font-size: 10px;
    }
  }

  &.circular {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: 1px 1px 8px cy-get-color("black", $alpha: 0.12);

    img {
      padding: 5px;
    }
  }
}

.handle-img-hover {
  position: relative;

  &:hover .cy-stack-avatar__update {
    visibility: visible !important;
  }
}

</style>
