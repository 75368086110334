<template>
  <v-stepper
    v-model="step"
    data-cy="service-wizard"
    vertical>
    <!-- Step 1: Select service -->
    <v-stepper-step
      :complete="!$isCreationRoute || hasServiceSelected"
      :editable="$isCreationRoute && hasServiceSelected"
      step="1"
      @click="onStepperClick">
      {{ $t('step1') }}
      <small v-if="!$isCreationRoute || hasServiceSelected">
        {{ _.get(service.catalog, 'name') || `${_.toLower($t('forms.loading'))}...` }}
      </small>
    </v-stepper-step>
    <v-stepper-content
      step="1"
      data-cy="select-service-step">
      <CyAlert
        class="ma-4 service-wizard__content"
        theme="error"
        :content="stackConfigErrors"/>

      <CyWizardServiceCatalog
        v-model="service"
        :stack-ref="theStackRef"
        @input="$isCreationRoute && step++ && resetFormsConfig()"/>

      <CyAlert class="mt-4">
        <i18n path="projectInfraImportNotification">
          <strong>{{ $t('didYouKnow') }}</strong>
          <a
            class="cy-link"
            target="_blank"
            :href="$docLinks.infraImport.index"
            rel="noopener noreferrer">
            {{ $t('ReadMore') }}
          </a>
        </i18n>
      </CyAlert>
    </v-stepper-content>

    <!-- Step 2: Setup environments -->
    <v-stepper-step
      :complete="hasServiceSelected && hasSelectedEnvironments"
      :step="2"
      :editable="hasServiceSelected && hasSelectedEnvironments"
      data-cy="setup-env-header"
      @click="onStepperClick">
      {{ $t('step2') }}
    </v-stepper-step>
    <v-stepper-content
      step="2"
      class="service-wizard__content"
      data-cy="setup-env-step">
      <div v-if="!_.isEmpty(existingEnvironments)">
        <span
          v-for="canonical of existingEnvironments"
          :key="canonical"
          class="font-weight-bold">
          {{ canonical }}
        </span>
      </div>
      <div class="input-group">
        <v-combobox
          ref="autocomplete"
          v-model="environments"
          :search-input.sync="envInput"
          :label="$t('forms.fieldOrgEnv')"
          :hint="$t('selectEnvHint')"
          :items="filteredEnvironments"
          :loading="!shouldAllowSelectingEnvironments"
          :error-messages="environmentsErrors"
          :disabled="!shouldAllowSelectingEnvironments"
          persistent-hint
          hide-selected
          multiple
          chips
          required
          class="required-field environments-selector"
          data-cy="env-input"
          @change="envInput = ''"
          @keydown.enter.capture.prevent="addCustomEnv"
          @keydown.tab.capture.prevent="addCustomEnv">
          <template #selection="{ item }">
            <span
              class="font-weight-bold mr-2"
              data-cy="selected-env">
              {{ item }}
            </span>
          </template>
          <template #item="{ item }">
            <v-list-item-content
              class="pa-0"
              data-cy="env-menu-item">
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <template slot="no-data">
            <v-list-item>
              <v-list-item-content class="pa-0">
                <v-list-item-title>{{ $t('addCustom') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>

        <v-switch
          v-if="hasFormsConfig"
          v-model="isFormsEnabled"
          :label="$t('useForms')"
          class="pt-0 mt-4 use-forms-switch"
          color="secondary">
          <CyTooltip
            slot="append"
            right>
            <template #activator="{ on }">
              <button
                class="cy-icon-btn--small cy-icon-btn--info"
                v-on="on">
                <v-icon class="cy-icon-btn__icon">
                  fa-question
                </v-icon>
              </button>
            </template>
            {{ $t('useFormsTooltip') }}
          </CyTooltip>
        </v-switch>
      </div>
      <div class="text-left mt-4">
        <CyButton
          v-if="!theStackRef"
          small
          variant="secondary"
          theme="secondary"
          class="mb-2"
          data-cy="env-prev-button"
          @click="prevStep()">
          {{ $t('forms.back') }}
        </CyButton>
        <CyButton
          :disabled="$v.$invalid"
          class="mb-2"
          small
          theme="secondary"
          data-cy="env-next-button"
          @click="nextStep()">
          {{ $t('forms.next') }}
        </CyButton>
      </div>
    </v-stepper-content>

    <!-- Step 3+: Configure each environment -->
    <template v-for="(env, index) of selectedEnvironments">
      <v-stepper-step
        :key="`step-${index}`"
        :step="index + 3"
        :complete="hasServiceSelected && hasSelectedEnvironments"
        :editable="hasServiceSelected && hasSelectedEnvironments"
        @click="onStepperClick">
        <span>
          {{ $t('configEnv') }}:
          <span
            class="font-weight-bold"
            data-cy="selected-env"
            :disabled="step < index + 3">
            {{ env }}
          </span>
        </span>
      </v-stepper-step>
      <v-stepper-content
        :key="`step-content-${index}`"
        :step="index + 3"
        data-cy="configure-env-step">
        <CyWizardUsecaseSelection
          v-if="hasMultipleUsecases"
          v-model="environmentsUsecaseKey[env]"
          :usecases="service.config"
          :confirm-change="!isConfigurationValid[env]"
          @reset="setConfigurationValidity(env, false)"/>

        <v-slide-y-transition>
          <section v-if="isEnvironmentUsecaseDefined(env)">
            <CyWizardStackForms
              v-if="isFormsEnabled && hasFormsConfig"
              :key="`step-content-${env}-forms-config`"
              v-model="rawFormsConfig[env]"
              :forms="environmentsConfiguration[env].forms"
              :stack-ref="service.catalog.ref"
              :environment-canonical="env"
              :use-case="environmentsUsecaseKey[env]"
              class="stackforms"
              @validate="setConfigurationValidity(env, $event)"/>

            <CyWizardEnvConfig
              v-else
              v-model="userConfigs[env]"
              :service-canonical="service.catalog.canonical"
              :environment="env.canonical"
              :project-canonical="projectCanonical"
              :config="environmentsConfiguration[env]"
              class="non-stackforms-editor"
              @valid="setConfigurationValidity(env, $event)"/>
          </section>
        </v-slide-y-transition>

        <div class="text-left mt-4">
          <CyButton
            small
            variant="secondary"
            theme="secondary"
            class="mb-2"
            data-cy="pipeline-prev-button"
            @click="prevStep()">
            {{ $t('forms.back') }}
          </CyButton>
          <CyTooltip
            right
            theme="error"
            :disabled="!toNextStepError(env)">
            <template #activator="{ on }">
              <span
                class="pt-3 pb-2"
                v-on="on">
                <CyButton
                  theme="secondary"
                  small
                  class="mb-2 ml-2"
                  data-cy="pipeline-next-button"
                  :disabled="!!toNextStepError(env)"
                  @click="nextStep()">
                  <template v-if="index < (selectedEnvironments.length - 1)">
                    {{ $t('nextEnv') }}
                  </template>
                  <template v-else>
                    {{ $t('reviewAndRun') }}
                  </template>
                </CyButton>
              </span>
            </template>
            {{ toNextStepError(env) }}
          </CyTooltip>
        </div>
      </v-stepper-content>
    </template>

    <!-- Last step: Review -->
    <v-stepper-step
      :step="lastStepNumber"
      :editable="isLastStepCompleted"
      @click="onStepperClick">
      {{ $t('step3') }}
    </v-stepper-step>
    <v-stepper-content
      :step="lastStepNumber"
      data-cy="review-step">
      <CyWizardRunService
        v-if="step >= lastStepNumber"
        :project-canonical="projectCanonical"
        :environments="selectedEnvironments"
        :environments-usecase-key="environmentsUsecaseKey"
        :raw-forms-config="rawFormsConfig"
        :has-forms="hasFormsConfig && isFormsEnabled"
        :service="service"
        :pipelines="pipelines"
        @back="prevStep()"
        @done="$router.push({
          name: 'pipeline',
          params: {
            orgCanonical,
            projectCanonical,
            envCanonical: _.head(selectedEnvironments),
            pipelineCanonical: `${projectCanonical}-${_.head(selectedEnvironments)}`,
          },
        })"/>
    </v-stepper-content>
  </v-stepper>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import CyWizardEnvConfig from '@/components/wizard/env-config.vue'
import CyWizardRunService from '@/components/wizard/run-service.vue'
import CyWizardServiceCatalog from '@/components/wizard/service-catalog.vue'
import CyWizardStackForms from '@/components/wizard/stack-forms.vue'
import CyWizardUsecaseSelection from '@/components/wizard/usecase-selection.vue'
import { pagination as createAPIPage } from '@/utils/api'
import REGEX from '@/utils/config/regex'
import { constructBreadcrumb } from '@/utils/helpers'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CyPageServiceWizard',
  components: {
    CyWizardServiceCatalog,
    CyWizardStackForms,
    CyWizardEnvConfig,
    CyWizardRunService,
    CyWizardUsecaseSelection,
  },
  breadcrumb () {
    const { projectCanonical, projectName } = this
    const header = {
      newService: this.$t('header.newService'),
      addServiceEnv: this.$t('header.addServiceEnv'),
    }[this.$route.name] || '...'

    return constructBreadcrumb(this.$options.name, header, [
      ...(!this.$isCreationRoute
        ? [
            {
              label: this.$t('routes.projectEnvironments'),
              name: 'projectEnvironments',
              params: { projectCanonical },
            },
            {
              name: 'project',
              label: projectName,
              params: { projectCanonical },
            },
          ]
        : []
      ),
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  header () {
    const title = {
      newService: this.$t('header.newService'),
      addServiceEnv: this.$t('header.addServiceEnv'),
    }[this.$route.name]

    return { title }
  },
  validations () {
    return {
      selectedEnvironments: {
        required,
        $each: {
          isUnique: (env) => !this.existingEnvironments.includes(env),
          isNameValid: (env) => REGEX.ENV_NAME.test(env),
        },
      },
    }
  },
  props: {
    projectCanonical: {
      type: String,
      default: '',
    },
    stackRef: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    envInput: '',
    stepCounter: 1,
    selectedEnvironments: [],
    service: {
      catalog: null,
      config: null,
    },
    userConfigs: {},
    rawFormsConfig: {},
    isConfigurationValid: {},
    isFormsEnabled: true,
    environmentsUsecaseKey: {},
    configRepositoriesFetched: false,
  }),
  computed: {
    ...mapState('organization', {
      available: (state) => state.available,
      organization: (state) => state.detail,
    }),
    ...mapState('organization/quota', {
      debouncingQuotas: (state) => state.debouncing,
      quotaRequirementErrors: (state) => state.requirementErrors,
    }),
    ...mapState('organization/stack', {
      stackConfigErrors: (state) => state.errors.stackConfig,
    }),
    ...mapGetters('organization/project', [
      'project',
    ]),
    ...mapGetters('organization/quota', {
      isEnvQuotaValid: 'isEnvValid',
    }),
    ...mapGetters('organization/stack', [
      'stack',
    ]),
    shouldAllowSelectingEnvironments () {
      return _.every(_.values(this.service))
    },
    hasFormsConfig () {
      const config = _.get(this.service, 'config', {})
      return _.values(config).some(({ forms }) => !_.isEmpty(forms))
    },
    step: {
      get () {
        return this.stepCounter
      },
      set (value) {
        if (!this.$isCreationRoute && value === 1) return
        if (value === 1) {
          this.selectedEnvironments = []
          this.service.catalog = null
        }
        this.stepCounter = value
      },
    },
    pipelines () {
      return this.selectedEnvironments.reduce((acc, env) => ({
        ...acc,
        [env]: {
          useCase: _.get(this.environmentsUsecaseKey, env),
          config: _.get(this.userConfigs, env),
        },
      }), {})
    },
    environmentsErrors () {
      const errors = []
      for (const [index, env] of _.entries(this.selectedEnvironments)) {
        const { isUnique, isNameValid } = this.$v.selectedEnvironments.$each[index] || {}
        if (!isUnique) errors.push(this.$t('environment.alreadyExists', { env }))
        if (!isNameValid) errors.push(this.$t('environment.nameInvalid'))
      }
      return errors
    },
    theStackRef () {
      return this.stackRef || this.project?.service_catalog?.ref
    },
    existingEnvironments () {
      return _.map(this.project?.environments, 'canonical')
    },
    environmentsConfiguration () {
      return _.reduce(this.environmentsUsecaseKey, (result, usecaseKey, env) => ({
        ...result, [env]: _.get(this.service.config, usecaseKey, null),
      }), {})
    },
    hasMultipleUsecases () {
      return _.size(this.service.config) > 1
    },
    lastStepNumber () {
      return this.selectedEnvironments.length + 3
    },
    hasServiceSelected () {
      return !!(this.service.catalog && this.service.config)
    },
    hasSelectedEnvironments () {
      return !_.isEmpty(this.selectedEnvironments)
    },
    isLastStepCompleted () {
      return !_.some(this.selectedEnvironments, this.toNextStepError) &&
        this.hasServiceSelected &&
        this.hasSelectedEnvironments &&
        !_.isEmpty(this.isConfigurationValid) &&
        _.every(this.isConfigurationValid) &&
        this.lastStepNumber >= 3
    },
    filteredEnvironments () {
      return _.map(this.available.environments, 'canonical')
        .filter((canonical) => !this.existingEnvironments.includes(canonical))
    },
    quotasEnabled () {
      return this.organization.quotas && this.stack?.quota_enabled
    },
    environments: {
      get () {
        return this.selectedEnvironments
      },
      set (environments) {
        this.$set(this, 'selectedEnvironments', _.map(environments, (env) => this.$getSlug(env)))
      },
    },
  },
  watch: {
    selectedEnvironments (newVal, oldVal) {
      this.setEnvironmentsUsecaseKey()

      for (const deletedEnv of _.difference(oldVal, newVal)) {
        this.$delete(this.isConfigurationValid, deletedEnv)
        this.$delete(this.rawFormsConfig, deletedEnv)
      }
    },
    step (newVal) {
      const step = Number(newVal)

      switch (step) {
        case 1:
          this.$set(this, 'service', { catalog: null, config: null })
          break

        case 2:
        case 4:
          this.fetchEnvs()
          break
      }
    },
  },
  async mounted () {
    if (!this.$isCreationRoute) this.step = 2
    await this.fetchEnvs()
    await this.FETCH_AVAILABLE({ keyPath: 'configRepositories' })
    this.configRepositoriesFetched = true
  },
  destroyed () {
    this.RESET_STACK_STATE()
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('organization/project', [
      'GET_PROJECT_ENVS',
    ]),
    ...mapMutations('organization/stack', [
      'RESET_STACK_STATE',
    ]),
    async fetchEnvs () {
      if (!this.$isCreationRoute) await this.GET_PROJECT_ENVS()
      await this.FETCH_AVAILABLE({ keyPath: 'environments', extraParams: [createAPIPage(1, 100)] })
    },
    addCustomEnv () {
      if (!this.envInput) return
      const inputText = this.envInput.toLowerCase().trim()
      this.envInput = inputText

      this.$set(this.rawFormsConfig, inputText, {})
      this.$set(this.isConfigurationValid, inputText, false)

      if (inputText && !this.selectedEnvironments.includes(inputText)) {
        this.selectedEnvironments.push(inputText)
        this.envInput = ''
      }
    },
    prevStep () {
      if (!this.$isCreationRoute && this.step === 2) return

      this.step -= 1
    },
    initRawFormsConfig () {
      this.$set(this, 'rawFormsConfig', {})
      for (const env of this.selectedEnvironments) this.$set(this.rawFormsConfig, env, {})
    },
    resetFormsConfig () {
      this.selectedEnvironments = []
      this.initRawFormsConfig()
      this.$set(this, 'isConfigurationValid', {})
      this.$set(this, 'isFormsEnabled', true)
    },
    nextStep () {
      this.step += 1
    },
    setConfigurationValidity (env, isValid) {
      this.$set(this.isConfigurationValid, env, isValid)
    },
    setEnvironmentsUsecaseKey () {
      this.environmentsUsecaseKey = _.reduce(this.selectedEnvironments, (result, env) => {
        if (!this.hasMultipleUsecases) {
          result[env] = _.first(_.keys(this.service.config))
          return result
        }

        result[env] = this.environmentsUsecaseKey[env] || null

        return result
      }, {})
    },
    isEnvironmentUsecaseDefined (env) {
      return !_.isEmpty(this.environmentsConfiguration[env])
    },
    onStepperClick () {
      // Small hack to fix dropdowns not closing when clicking on some parts of the stepper
      this.$root.$el.click()
    },
    toNextStepError (env) {
      if (this.quotasEnabled && _.isEmpty(this.available.quotas) && !this.fetchingQuotas) return this.$t('noQuotas')
      if (this.quotaRequirementErrors[env]) return this.$t('unexpectedQuotaError')
      if (!this.isConfigurationValid[env] || (this.hasMultipleUsecases && !this.environmentsUsecaseKey[env])) return this.$t('emptyRequiredFields')
      if (this.quotasEnabled && (!this.isEnvQuotaValid(env) || this.debouncingQuotas[env])) return this.$t('exceedingTeamQuota')
      return null
    },
  },
  i18n: {
    messages: {
      en: {
        title: {
          newService: 'Create project',
          addServiceEnv: 'Create environments',
        },
        header: {
          newService: '@:title.newService',
          addServiceEnv: '@:title.addServiceEnv',
        },
        addCustom: 'Press the ENTER key to add a name to the list',
        configEnv: 'Configure environment',
        didYouKnow: 'Did you know?',
        emptyRequiredFields: 'Some required fields are empty',
        exceedingTeamQuota: 'Exceeding team quota',
        nextEnv: 'Next environment',
        noQuotas: 'Selected team has no quotas',
        projectInfraImportNotification: '{0} You can also import your cloud infrastructure to generate stacks and projects. {1}',
        reviewAndRun: 'Review and run',
        selectEnv: 'Select your environments',
        selectEnvHint: 'Type the environment name then press enter or tab. Only hyphens, underscores and alphanumeric characters allowed.',
        setupCRBtn: 'Setup CRs',
        step1: 'Select the service bundle',
        step2: 'Setup your environments',
        step3: 'Review and Run',
        unexpectedQuotaError: 'An unexpected error occured when fetching quota requirements',
        useDefaultPipeline: 'Apply a default pipeline to every environment',
        useForms: 'Use StackForms interface',
        useFormsTooltip: 'Use StackForms interface if enabled, or the code editor if not',
        warningNoConfigRepositories: 'No config repositories have been found. To complete project creation you need to set up at least one.',
      },
      es: {
        title: {
          newService: 'Crear proyecto',
          addServiceEnv: 'Crear entornos',
        },
        header: {
          newService: '@:title.newService',
          addServiceEnv: '@:title.addServiceEnv',
        },
        addCustom: 'Presiona INTRO para añadir un nombre a la lista',
        configEnv: 'Configura el entorno',
        didYouKnow: '¿Sabías?',
        emptyRequiredFields: 'Algunos campos obligatorios están vacíos',
        exceedingTeamQuota: 'Excediendo la quota del equipo',
        nextEnv: 'Siguiente entorno',
        noQuotas: 'El equipo seleccionado no tiene quotas',
        projectInfraImportNotification: '{0} También puede importar su infraestructura en la nube para generar pilas y proyectos. {1}',
        reviewAndRun: 'Revisar y Ejecutar',
        selectEnv: 'Define tus entornos',
        selectEnvHint: 'Escriba el nombre del entorno y confirmelo con Enter. Solo están permitidos carácteres alfanuméricos, guiones y guiones bajos.',
        setupCRBtn: 'Configurar CRs',
        step1: 'Elige un servicio',
        step2: 'Configurar los entornos',
        step3: 'Revisar y ejecutar servicio',
        unexpectedQuotaError: 'Se produjo un error inesperado al obtener los requisitos de quota',
        useDefaultPipeline: 'Aplicar una pipeline predeterminada a todos los entornos',
        useForms: 'Usar interfaz StackForms',
        useFormsTooltip: 'Habilitar por usar la interfaz StackForms, deshabilitar por usar el editor de código',
        warningNoConfigRepositories: 'No se han encontrado repositorios de configuración. Para completar la creación del proyecto, debe configurar al menos uno.',
      },
      fr: {
        title: {
          newService: 'Créer un projet',
          addServiceEnv: 'Créer des environnements',
        },
        header: {
          newService: '@:title.newService',
          addServiceEnv: '@:title.addServiceEnv',
        },
        addCustom: 'Appuyez sur Entrée pour ajouter un nom à la liste',
        configEnv: `Configurez l'environnement`,
        didYouKnow: 'Le saviez-vous ?',
        emptyRequiredFields: 'Certains champs obligatoires sont vides',
        exceedingTeamQuota: `Dépassement du quota de l'équipe`,
        nextEnv: 'Suivant environnement',
        noQuotas: `L'équipe sélectionnée n'a pas de quotas`,
        projectInfraImportNotification: '{0} Vous pouvez également importer votre infrastructure cloud pour générer des stacks et des projets. {1}',
        reviewAndRun: 'Vérifier et Lancer',
        selectEnv: 'Definissez vos environnements',
        selectEnvHint: `Veuillez écrire le nom de l'environnement et valider avec la touche Entrée. Seuls les caractères alphanumériques, traits d'union et tirets bas sont permis.`,
        setupCRBtn: 'Configurer CRs',
        step1: 'Choisissez un service',
        step2: 'Configurez vos environnements',
        step3: 'Vérification et création du service',
        unexpectedQuotaError: `Une erreur innatendue s'est produite lors du calcul des quotas`,
        useDefaultPipeline: 'Appliquer une pipeline par défaut à tous les environnements',
        useForms: `Utiliser l'interface StackForms`,
        useFormsTooltip: `Utilisez l'interface StackForms si elle est activée, ou l'éditeur de code sinon`,
        warningNoConfigRepositories: `Aucune source de configuration n'a été trouvée. Pour terminer la création du projet, vous devez en configurer au moins une.`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .v-stepper {
    background: transparent;

    &:not(.v-sheet--outlined) {
      box-shadow: none !important;
    }
  }

  .v-stepper--vertical {
    margin-left: -24px;

    .v-stepper__content {
      margin: -8px 0 -16px 36px;
      padding: 16px 0 16px 23px;
    }
  }

  .input-group {
    max-width: 450px;
  }

  .list__tile__avatar {
    min-width: 48px;
  }

  .warning-box {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    margin: 4px auto;
    padding: 16px;
    border: 1px solid !important;
    border-radius: 5px;
    border-color: cy-get-color("warning");
    color: cy-get-color("warning");
    font-size: 14px;

    .v-icon {
      padding-right: 10px;
      color: cy-get-color("warning");
    }
  }

  .use-forms-switch {
    display: inline-flex;
  }

  .environments-selector {
    ::v-deep .v-list-item {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .stackforms {
    background-color: cy-get-color("white");
  }

  .non-stackforms-editor {
    ::v-deep {
      .code-container,
      .list-container {
        min-height: 400px;
      }
    }
  }
 </style>
